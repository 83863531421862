import React from "react"
import { css, Theme } from "@emotion/react"
import { Paragraph } from "@ncs/web-legos"

const BottomBar: React.FC = () => (
	<div css={bottomBarStyle}>
		<div css={termsStyle}>
			<Paragraph mr={4} color="white">
				<a
					css={link}
					href="https://ncswash.com/wp-content/uploads/2023/06/NCS-PO-STANDARD-TERMS-AND-CONDITIONS-1.pdf"
					target="_blank"
					rel="noreferrer"
				>
					Terms & Conditions
				</a>
			</Paragraph>
			<Paragraph color="white" light>
				<a
					css={link}
					href="https://ncswash.com/privacy-policy/"
					target="_blank"
					rel="noreferrer"
				>
					Privacy Policy
				</a>
			</Paragraph>
		</div>
		<Paragraph color="white" fontSize={0.875}>
			© {new Date().getFullYear()} National Carwash Solutions, Inc. All Rights Reserved.
		</Paragraph>
	</div>
)

export default BottomBar

const bottomBarStyle = (theme: Theme) => css`
	background-color: #00192f;
	padding: 1.5rem 2rem;
	display: flex;
	justify-content: space-between;
	${theme.breakpoints.down("xs")} {
		flex-direction: column;
	}
`

const termsStyle = (theme: Theme) => css`
	display: flex;
	${theme.breakpoints.down("xs")} {
		margin-bottom: 1rem;
	}
`

const link = () => css`
	color: #ffffff;
	text-decoration: none;
	font-weight: 500;
	font-size: 0.875rem;
`
