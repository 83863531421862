import React from "react"

import { css, Theme } from "@emotion/react"

import { Box, Button, Heading, Icon, Modal, Paragraph } from "@ncs/web-legos"
import confetti from "canvas-confetti"

interface OrderReceivedModalProps {
	isOpen: boolean
	onClose: () => void
	orderNumber: string | number | null
}

const confettiAnimation = () => {
	const duration = 2 * 1000
	const end = Date.now() + duration
	const colors = ["#0B75E1", "#ffffff", "#dc2626"]
	const myCanvas = document.createElement("canvas")
	Object.assign(myCanvas.style, {
		position: "fixed",
		top: "0",
		left: "0",
		display: "block",
		width: "100%",
		height: "100%",
		pointerEvents: "none",
		zIndex: "99999",
	})
	document.body.appendChild(myCanvas)

	const myConfetti = confetti.create(myCanvas, {
		resize: true,
	})

	;(function frame() {
		void myConfetti({
			particleCount: 3,
			angle: 60,
			spread: 55,
			origin: { x: 0 },
			colors: colors,
		})
		void myConfetti({
			particleCount: 3,
			angle: 120,
			spread: 55,
			origin: { x: 1 },
			colors: colors,
		})

		if (Date.now() < end) {
			requestAnimationFrame(frame)
		}
	})()

	setTimeout(() => {
		myConfetti.reset()
		document.body.removeChild(myCanvas)
	}, duration * 2)
}

export const OrderReceivedModalEcomm: React.FC<OrderReceivedModalProps> = ({
	isOpen,
	onClose,
	orderNumber,
}) => {
	if (!orderNumber) {
		return null
	} else if (isOpen) {
		confettiAnimation()
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} maxWidth="md" showCloseButton={false}>
			<Box textAlign="center" pb={3}>
				<Icon css={iconStyle} icon="check-circle" family="solid" />
				<Heading variant="h1" my={3}>
					Thanks! Order #{orderNumber} has been received
				</Heading>
				<Paragraph maxWidth="25rem" mx="auto" my={3}>
					You will receive an email shortly confirming your order. Thank you for choosing
					NCS.
				</Paragraph>
				<Button variant="secondary-cta" onClick={onClose}>
					Done
				</Button>
			</Box>
		</Modal>
	)
}

const iconStyle = (theme: Theme) => css`
	font-size: 4rem;
	color: ${theme.palette.success.main};
`
