import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { LineItemType } from "../common"
import { LocationMinimal } from "../inventory"
import { TerritoryMinimal, UserAndEmailSerializer, UserMinimal, UserProfile } from "../security"

export enum CustomersUrlPaths {
	AccessorialDefaults = "accessorial_defaults",
	AlternateAddresses = "alternate_addresses",
	BayEquipment = "bay-equipment",
	BillToCustomers = "bill_to_customers",
	BillTos = "bill_tos",
	BrandCards = "brand_cards",
	BulkCustomerAssignment = "bulk_customer_assignment",
	BulkCustomerMeta = "bulk_customer_meta",
	CarouselImages = "carousel_images",
	CartSites = "cart_sites",
	Contacts = "contacts",
	Contracts = "contracts",
	CreateForChildren = "create_for_children",
	CreateForSiblings = "create_for_siblings",
	CustomerContactRequest = "customer_contact_request",
	CustomerEquipment = "customer_equipment",
	Customers = "customers",
	CustomerSites = "customer_sites",
	Details = "details",
	Dispatches = "dispatches",
	Equipment = "equipment",
	Invoices = "invoices",
	Manufacturers = "manufacturers",
	Minimal = "minimal",
	Models = "models",
	NewUserRequest = "new_user_request",
	OnlineChemicals = "online_chemicals",
	Part = "part",
	Parts = "parts",
	Preferences = "preferences",
	QuickOrder = "quick_order",
	Site = "site",
	SiteSurveys = "site_surveys",
	Superuser = "superuser",
	Types = "types",
	Users = "users",
	Vintages = "vintages",
	WorkOrderMachine = "work_order_machine",
}

export interface CustomerContract {
	id: string
	customerEquipmentId: string
	number: string
	type: string
}

export interface CustomerChemical {
	id: string
	lowLimitIndicatorInGallons: string
	isActive: boolean
	// This maybe should be an object.
	// https://github.com/ncs-ryko/ncsportal/blob/e8b73d077f21800e6e1cac8755854687ded32049/web_portal/connectivity/models.py#L196
	chemical: string
}

export interface Customer {
	id: string
	acquisition: string | null
	address1: string
	address2: string
	address3: string
	arTerm: string | null
	cell: string
	chemicalDiscountPercent: string
	city: string
	cityTax: string
	contactName: string
	contracts: CustomerContract[]
	countyTax: string
	creditCheckStatus: string
	customerChemical: CustomerChemical[]
	customerClass: {
		id: string
		description: string
	} | null
	customerSubclass: {
		id: string
		description: string
	} | null
	customerType: {
		id: string
		description: string
	} | null
	customerNumber: string
	defaultBillNotToExceed: string | null
	defaultTechAssociationId: number | null
	detailDiscountPercent: string
	districtTax: string
	doNotContact: boolean
	documentationEmail: string | null
	email: string | null
	fax: string | null
	fuelSurchargeBillable: boolean // Used for Service Surcharge Billable in the UI
	isActive: boolean
	isCommercialWashCustomer: boolean
	isFreightTaxed: boolean
	isFstActive: boolean
	isInternal: boolean
	isLaborTaxed: boolean
	isMilesTaxed: boolean
	isNationalAccount: boolean
	isPartsTaxed: boolean
	isPremMaintTaxed: boolean
	isServiceCustomer: boolean
	isStandardDescription: boolean
	name: string
	organization: {
		id: string
		name: string
		enterprise: {
			id: string
			name: string
		}
	} | null
	partDiscountPercent: string
	phone: string
	postalcode: string // [sic]
	pricingMatrix: {
		id: number
		description: string
	} | null
	pricingMatrixId: number | null
	salesTaxNumber: string
	servicable: boolean // cspell:disable-line [sic]
	siteNumber: string | null
	slotType: string | null
	state: string
	stateTax: string
	territory: TerritoryMinimal | null
	useAlternateLabor: boolean
	freightClass: FreightClassDescription | null
	freightClassId: FreightClassId | null
	chemicalSurchargeBillable: boolean
	poRequired: boolean
}

/**
 * When you hit the customer ModelViewSet as detail rather than many, you get more fields.
 */
export type CustomerDetail = Customer & {
	billableTo:
		| {
				id: string
				customerNumber: string
				description: string | null
				isDefault: boolean
				name: string
		  }[]
		| null
	freightPhone: string[]
	currentFlatRate: {
		id: string
		rate: number
		group: string | null
	}
	currentLaborRate: {
		id: string
		rate: number
		group: string | null
	}
	defaultTech: UserMinimal | null
	defaultChemTech: UserMinimal | null
	defaultChemTechAssociation: {
		id: string
		description: string | null
	}
	defaultTechAssociation: {
		id: string
		description: string | null
	}
	partGroup: {
		id: string
		discount: number
		name: string
	} | null
	partGroupList: {
		id: string
		discount: number
		name: string
	}[]
	priceMatrixList: {
		id: string
		name: string
	}[]
	rdc: LocationMinimal | null // If null, fall back to Grimes.
	netsuiteId: string | null
}

export interface CustomerQueryParams extends SearchQueryParams {
	fields: string | null
	service: boolean | null
	accountActive: boolean | null
	isServiceCustomer: boolean | null
	isActive: boolean | null
	isInternal: boolean | null
	isWarehouse: boolean | null
	showKbmManaged: boolean | null
	showNotKbmManaged: boolean | null
	excludeTerritory: number | null
	pricingMatrixId: string | null
	territory: string | null
	enterprise: string | null
	region: string | null
	organization: string | null
	partOrderReceivable: boolean | null
}

// I don't know which of these fields are actually updatable or not, but the PATCH
// endpoint expects at least some of them to be there, so I'm just copying the way it
// was done in the previous UI and sending them all together always.
export interface UpdateCustomerPatch {
	address1: string | null
	address2: string | null
	chemicalDiscountPercent: number
	city: string | null
	contactName: string | null
	customerClassId: string | null
	customerSubclassId: string | null
	customerNumber: string | null
	customerTypeId: string | null
	detailDiscountPercent: number
	email: string | null
	fuelSurchargeBillable: boolean | null // Used for service surcharge
	isFstActive: boolean | null
	name: string | null
	organizationId: string | null
	partDiscountPercent: number
	phone: string | null
	postalcode: string | null
	rdcId: string | null
	siteNumber: string | null
	state: string | null
	useAlternateLabor: boolean | null
	freightClassId: FreightClassId | null
	chemicalSurchargeBillable: boolean | null
	poRequired: boolean | null
}

export type CustomerSite = {
	id: string
	address1: string | null
	address2: string
	city: string
	customerNumber: string
	latitude: string | null
	longitude: string | null
	name: string
	postalCode: string
	zip: string
	state: string
	phone: string | null
}

export type CustomerMinimal = {
	id: string
	name: string | null
	customerNumber: string
	isFstActive?: boolean // There are multiple CustomerMinimal serializers, and some have this field and some do not.
}

export type CustomerMinimaler = {
	id: string
	name: string | null
	customerNumber: string
}

export type CustomerWithAddress = {
	id: string
	territory: TerritoryMinimal | null
	isFstActive: boolean
	name: string | null
	customerNumber: string
	address1: string | null
	address2: string | null
	address3: string | null
	phone: string | null
	city: string | null
	state: string | null
	postalcode: string | null // [sic]
}

export interface CustomerWithTerritory {
	id: string
	name: string
	customerNumber: string
	territory: TerritoryMinimal | null
}

export interface CustomerAddressWithoutFst {
	id: string
	territory: TerritoryMinimal | null
	name: string
	customerNumber: string
	address1: string | null
	address2: string
	address3: string | null
	phone: string | null
	city: string
	state: string
	postalcode: string // [sic]
}

export interface EquipmentContract {
	id: string
	effectiveDate: string
	expirationDate: string
	isActive: boolean
	type: string
}

export interface VintageDocument {
	id: string
	documentNumber: string
	filename: string
	description: string
}

export interface CustomerEquipmentVintage {
	id: string
	beginSerialNumber: string | null
	code: string | null
	documents: VintageDocument[]
	endSerialNumber: string | null
	eqpModelId: number | null
	isActive: boolean
	name: string | null
}

export interface CustomerEquipment {
	id: string
	contracts: EquipmentContract[]
	customer: CustomerMinimal
	eqpVintage: CustomerEquipmentVintage | null
	frameWarrantyExpireDate: string | null
	installDate: string | null
	partsWarrantyExpireDate: string | null
	laborWarrantyExpireDate: string | null
	serialNumber: string | null
}

export interface CustomerEquipmentQueryParams extends SearchQueryParams, OrderingQueryParams {}

export interface CustomerEquipmentDispatch {
	id: string
	callReceived: string | null
	closedDate: string | null
	dispatchNumber: string | null
}

export interface CustomerOnlineChemical {
	id: string
	customer: {
		customerNumber: string
		name: string
		id: number
	}
	customerId: string
	defaultQuantity: number
	isActive: boolean
	onlinePart: {
		id: string
		description: string
		featuredItem: boolean
		clearanceItem: boolean
		flatShippingRate: string | null
		imageUrl: string | null
		isActive: boolean
		isChild: boolean
		variantCount: number
		isParent: boolean
		isShippedFlatRate: boolean

		netPrice: number
		onlinePartNumber: string
		childNetPrice: number
		part: {
			id: string
			description: string
			partNumber: string
			unitOfMeasure: string
		}
		brandName: string
		price: string
		shippingLeadDays: number
		title: string
	}
	onlinePartId: string
}

export interface CustomerCartSite {
	city: string | null
	customerId: number
	name: string | null
	postalcode: string | null
	state: string | null
}

export interface EquipmentType {
	id: number
	name: string
	description: string
}

export interface CustomerBillTo extends Customer {
	description: string
	isDefault: boolean
}

export interface CustomerAlternateAddress<Id = string> {
	id: Id
	isActive: boolean
	inactiveDate: string | null
	customerId: Id
	name: string | null
	description: string
	address2: string
	address1: string | null
	city: string | null
	state: string | null
	postalcode: string | null
}

export interface NewCustomerAlternateAddressPost {
	description: string
	name: string | null
	address2: string
	address1: string | null
	city: string
	state: string
	postalcode: string
}

export interface CreateAccountPost {
	firstName: string
	lastName: string
	email: string
	password: string
	customerNumber?: string | null
	// this last 3 are not in use
	previousPurchase?: boolean
	companyName?: string | null
	invoiceNumber?: string | null
}

export interface CustomerUserContactType {
	id: string
	description: string
}

export interface CustomerUserContactRecord {
	requestedBy: string
	typeId: number
	typeDescription: string
	sentOn: string
}

export interface CustomerUserContactRecordsQueryParam {
	user: string | null
}

export interface CustomerUserContactPost {
	user: string
	requestType: number
}

export interface QuickOrder {
	id: number
	description: string
}

export interface QuickOrderPost {
	description: string
}

export interface QuickOrderPatch {
	quickOrderId: number
	description: string
}

export interface QuickOrderDetails {
	quickOrderId: string
	description: string
	parts: {
		partNumber: string
		description: string
		onlinePartId: string
	}[]
	sites: {
		customerId: number
		customerName: string
		customerNumber: string
	}[]
}

export interface QuickOrderPartPost {
	onlinePartId: number
	quickOrderId: number
}

export interface QuickOrderPartDelete {
	onlinePartId: number
	quickOrderId: number
}

export interface QuickOrderSitePost {
	customerId: number
	quickOrderId: number
}

export interface QuickOrderSiteDelete {
	customerId: number
	quickOrderId: number
}

export interface CustomerBrandCard {
	id: string
	name: string
	taglineText: string
	buttonLabel: string
	buttonDestinationUrl: string
	buttonDestinationOpensNewWindow: boolean
	logoImageUrl: string
	bannerImageUrl: string
	presentationOrder: number
	modifiedOn: string | null
}

export interface CustomerCarouselImage {
	id: string
	name: string
	text: string
	destinationUrl: string
	destinationOpensNewWindow: boolean
	desktopImageUrl: string
	mobileImageUrl: string
	tabletImageUrl: string
	tvImageUrl: string
	presentationOrder: number
	modifiedOn: string | null
}

/**
 * Returned in search results on Customer Management page.
 */
export interface CustomerMin {
	id: string
	address1: string
	address2: string
	address3: string
	cell: string
	city: string
	contactName: string
	customerNumber: string
	email: string
	fax: string
	isActive: boolean
	isFstActive: boolean
	name: string
	phone: string
	postalcode: string
	servicable: boolean // cspell:disable-line
	siteNumber: string
	state: string
	territory: TerritoryMinimal | null
}

export interface CustomerMinQueryParams extends SearchQueryParams {
	region: string | null
	territory: string | null
	enterprise: string | null
	organization: string | null
	isFstActive: boolean | null // Doesn't do anything?
	service: boolean | null
	customer: string | null
}

export interface CustomerContacts {
	dispatch: {
		emails: string[]
		phones: string[]
	}
	partOrder: {
		emails: string[]
		phones: string[]
	}
	invoice: {
		emails: string[]
		phones: string[]
	}
	workOrder: {
		emails: string[]
		phones: string[]
	}
}

export interface CustomerContact {
	id: number
	destination: string
	communicationReason: string
	communicationChannel: string
	notificationType: number
}

export interface CreateCustomerContactsPost {
	notificationType: "dispatch" | "part_order" | "invoice" | "work_order"
	communicationType: "phone" | "email"
	values: string[]
	customer: string
}

export interface CreateCustomerContactPost {
	destination: string
	communication_channel: string
	communication_reason: string
	notification_type: number
}

/**
 * There are multiple `CustomerEquipmentSerializer` in backend. This is the one in `customers/serializers.py`.
 */
export interface Equipment {
	id: string
	bay: string | null
	contracts: EquipmentContract[]
	frameWarrantyExpireDate: string | null
	installDate: string | null
	isActive: boolean
	laborWarrantyExpireDate: string | null
	model: string | null
	modelId: number | null
	partsWarrantyExpireDate: string | null
	requiresWashCounts: boolean
	serialNumber: string
	warrantyContracts: WarrantyContract[]
}

export interface CreateEquipmentPost {
	serialNumber: string
	isActive: boolean
	eqpVintageId: string
	modelId: string
	installDate: string | null
	bay: string | null
	requiresWashCounts: boolean
	frameWarrantyExpireDate: string | null
	laborWarrantyExpireDate: string | null
	partsWarrantyExpireDate: string | null
}

export type UpdateEquipmentPatch = Partial<CreateEquipmentPost> & {
	keepWorkorderHistory?: boolean
	moveContractsTo?: string
}

export interface WarrantyContract {
	id: string
	customer: CustomerDetail | null
	effectiveDate: string
	expirationDate: string
}

export type UpdateEquipmentContractPatch = Partial<{
	expirationDate: string
	isActive: boolean
}>

export interface SurveyResponse {
	id: string
	assignedDate: string | null
	assignedTo: UserMinimal
	createdDate: string | null
	customer: CustomerMinimal | null
	isSubmitted: boolean
	requestedBy: UserAndEmailSerializer
	surveyDocument360: SurveyDocument360[]
	surveyDocumentFull: SurveyDocumentFull[]
	surveyId: number
}

export interface SurveyDocument360 {
	id: string
}

export interface SurveyDocumentFull {
	id: string
}

export interface CreateSiteSurveyPost {
	assignedToId: string
}

export interface Portal {
	id: string
	description: string
}

export interface AdditionalUser {
	id: string
	email: string
	firstName: string
	isCustomer: boolean
	isCustomerSuperuser: boolean
	lastLogin: string | null
	lastName: string
	mobile: string | null
	poRequired: boolean
	sites: string[]
}

export interface AdditionalUserDetails {
	profile: UserProfile
	sites: CustomerSite[]
}

export interface CreateAdditionalUserPost {
	firstName: string
	lastName: string
	email: string
	mobile: string | null
	poRequired: boolean
	sites: string[]
}

export type UpdateAdditionalUserPatch = Partial<CreateAdditionalUserPost>

export interface CustomerType {
	id: string
	description: string
}

export interface CustomerClass {
	id: string
	description: string
}

export interface CustomerSubclass {
	id: string
	description: string
	customerClass: CustomerClass
}

export interface FreightClass {
	id: FreightClassId
	description: FreightClassDescription
}

export enum FreightClassId {
	IBA = "1",
	Tunnel = "2",
	Distributor = "3",
	NCSServiceTruck = "4",
}

export enum FreightClassDescription {
	IBA = "IBA / Other",
	Tunnel = "Tunnel",
	Distributor = "Distributor",
	NCSServiceTruck = "NCS Service Truck",
}

export interface CustomerAccessorial {
	lineItemTypeId: LineItemType
	rate: number
	freeFreightExcluded: boolean
	lineItemDescription: string
	default: boolean
}

export interface PostCustomerAccessorialUpdate {
	lineItemTypeId: LineItemType
	default: boolean
	customerId: string
}

export interface InvoiceHistoryItem {
	id: string
	arrivedDate: string | null
	dispatch: {
		id: string
		symptoms: string | null
	} | null
	invoiceNumber: string
	laborTotal: string
	otherTotal: string
	status: number
	statusCode: string
	subtotal: string
	taxTotal: string
	total: string
	totalParts: string
	workCompletedDate: string | null
}

export type BillToCustomers = {
	id: string
	customerNumber: string
	name: string
}[]

export interface BillToCustomersQueryParams {
	customer: string | null
}

export interface EquipmentVintage {
	id: string
	beginSerialNumber: string | null
	code: string | null
	documents: VintageDocument[]
	endSerialNumber: string | null
	eqpModel: EquipmentModel | null
	eqpModelId: number | null
	isActive: boolean
	name: string | null
}

export type EquipmentVintageQueryParams = OrderingQueryParams &
	SearchQueryParams & {
		model: string | null
		manufacturer: string | null
		isActive: boolean | null
	}

export interface CreateEquipmentVintagePost {
	beginSerialNumber: string | null
	code: string // Just send the same as the name I guess?
	endSerialNumber: string | null
	name: string
	isActive: boolean
	eqpModelId: string
}

export interface UpdateEquipmentVintagePatch {
	beginSerialNumber: string | null
	code: string // Just send the same as the name I guess?
	endSerialNumber: string | null
	name: string
	isActive: boolean
}

export interface EquipmentModel {
	id: string
	eqpFamily: {
		id: string
		code: string
		description: string
	} | null
	eqpFamilyId: number
	isActive: boolean
	isPrimary: boolean
	manufacturer: {
		id: number
		code: string | null
		effDate: string
		endDate: string
		isActive: boolean
		name: string | null
		portalId: string | null
		sequence: number | null
	} | null
	manufacturerId: number | null
	modelName: string
	omitFromCustomerEquipment: boolean
}

export type EquipmentModelsQueryParams = SearchQueryParams &
	OrderingQueryParams & {
		manufacturerId: string | null
		modelName: string | null
		isActive: boolean | null
	}

export interface CreateEquipmentModelPost {
	eqpFamilyId: number
	isActive: boolean
	manufacturerId: number
	modelName: string
}

export type UpdateEquipmentModelPatch = {
	modelName: string
	isActive: boolean
	isPrimary: boolean
}

export interface EquipmentManufacturer {
	id: number
	code: string | null
	effDate: string
	endDate: string
	isActive: boolean
	name: string | null
	portalId: string | null
	sequence: number | null
}

export type EquipmentManufacturersQueryParams = SearchQueryParams &
	OrderingQueryParams & {
		isActive: boolean | null
	}

export interface CreateEquipmentManufacturerPost {
	name: string
	code: string
	isActive: boolean
}

export interface UpdateEquipmentManufacturerPatch {
	name: string
	code: string
	isActive: boolean
}

export interface EquipmentVintageCustomer {
	id: string // ID of the CustomerEquipment, not the Customer
	bay: string | null
	contracts: EquipmentContract[]
	customer: CustomerMinimal
	eqpVintageId: number
	frameWarrantyExpireDate: string | null
	installDate: string | null
	isActive: boolean
	laborWarrantyExpireDate: string | null
	partsWarrantyExpireDate: string | null
	serialNumber: string | null
}

export interface EquipmentVintagePart {
	// Sigh...
	part_Description: string
	part_PartFamily: string
	part_PartNumber: string
	partId: number // Dangerously yuge.
	totalQuantity: number
}

export interface EquipmentVintageWorkOrder {
	id: string
	dispatch: {
		id: string
		dispatchNumber: string
		callReceived: string | null
		customer: CustomerWithTerritory
		callType: {
			id: string
			description: string
		} | null
	}
	workorder: {
		id: string
		workorderNumber: string | null
		openDate: string
	} | null
}

export interface ShoppingCart {
	id: string
	description: string | null
	flatShippingRate: string | null
	imageUrl: string | null
	isActive: boolean
	isShippedFlatRate: boolean
	netPrice: number
	childNetPrice: number | null
	brandName: string | null
	quantity: number
}

export interface ShoppingCart {
	part: ShoppingCartPartDetails
	quantity: number
}

export interface ShoppingCartPartDetails {
	id: string
	description: string
	imageUrl: string | null
	isActive: boolean
	price: string
	part: PartInfo
	onlinePartNumber: string
	title: string
	flatShippingRate: string | null
	isParent: boolean
	isShippedFlatRate: boolean
	shippingLeadDays: number
	featuredItem: boolean
	clearanceItem: boolean
	isChild: boolean
	variantCount: number | null
	brandName: string
	netPrice: number
	childNetPrice: number | null
}

export interface PartInfo {
	unitOfMeasure: string
	part: any
	imageUrl: string | null
	id: string | null
	title: string | null
	netPrice: string | null
	partNumber: string | null
	price: string | null
	quantity: number
}

export type CustomerMetaDataBulkPost = FormData

export type CustomerAssignmentBulkPost = FormData
