import React from "react"
export interface UnlockedIconProps {
	color?: string
}

export const UnlockedIcon: React.FC<UnlockedIconProps> = ({ color, ...rest }) => {
	return (
		<span {...rest}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="16"
				viewBox="0 0 18 16"
				fill="none"
			>
				<path
					d="M11.5 4C11.5 2.61875 12.6187 1.5 14 1.5C15.3813 1.5 16.5 2.61875 16.5 4V6.25C16.5 6.66563 16.8344 7 17.25 7C17.6656 7 18 6.66563 18 6.25V4C18 1.79063 16.2094 0 14 0C11.7906 0 10 1.79063 10 4V6H2.5C1.39688 6 0.5 6.89687 0.5 8V14C0.5 15.1031 1.39688 16 2.5 16H12.5C13.6031 16 14.5 15.1031 14.5 14V8C14.5 6.89687 13.6031 6 12.5 6H11.5V4ZM2.5 7.5H12.5C12.775 7.5 13 7.725 13 8V14C13 14.275 12.775 14.5 12.5 14.5H2.5C2.225 14.5 2 14.275 2 14V8C2 7.725 2.225 7.5 2.5 7.5Z"
					fill="#111827"
				/>
			</svg>
		</span>
	)
}
