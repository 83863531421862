import { FC, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useUrlState, TrackingEvent, trackEvent } from "@ncs/web-legos"
import { useShopContext } from "~/contexts"
import { PageContentWrapper, PartPreviewRowEcomm } from "~/shared-components"
import { BrandCtaGridEcomm, OrderReceivedModalEcomm, ShopCarousel } from "./components"
import { ShopUrlState } from "./Shop"

export const ShopEcomm: FC = () => {
	const history = useHistory()
	const [{ recentlyViewedPartIds }] = useShopContext()

	// If we're redirected here from checkout after finishing an order, show confirmation.
	const [{ fromOrder }] = useUrlState<ShopUrlState>({ fromOrder: null })
	const [showOrderReceivedModal, setShowOrderReceivedModal] = useState(false)

	useEffect(() => {
		if (fromOrder) {
			setShowOrderReceivedModal(true)
			trackEvent(TrackingEvent.CONGRATS)
		}
	}, [fromOrder])

	return (
		<PageContentWrapper breadcrumbs={[{ name: "Shop" }]} title="Shop">
			<ShopCarousel />

			<BrandCtaGridEcomm />

			<PartPreviewRowEcomm
				heading="Recently Viewed Products"
				partIds={recentlyViewedPartIds}
			/>

			<OrderReceivedModalEcomm
				isOpen={showOrderReceivedModal}
				onClose={() => {
					setShowOrderReceivedModal(false)
					history.replace("/shop")
				}}
				orderNumber={fromOrder}
			/>
		</PageContentWrapper>
	)
}
