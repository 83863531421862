import { createTheme } from "@material-ui/core/styles"
import { darken, transparentize } from "polished"

export const NCS_FONT = "'Atlas Grotesk', 'Helvetica', 'Arial', sans-serif"
export const NCS_PRIMARY = "#002648"

export const theme = createTheme({
	defaultCardElevation: "15px",
	defaultLinkTextDecoration: "underline",
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: "rgba(0, 0, 0, 0.25)",
			},
		},
		MuiTooltip: {
			arrow: {
				color: "white",
			},
			tooltip: {
				backgroundColor: "white",
				boxShadow: "0 2px 30px rgba(0, 0, 0, 0.2)",
				padding: "0.6rem 1rem 0.85rem 1rem",
				fontSize: "1rem",
				color: "#393939",
				"& .icon-container": {
					position: "relative",
					top: "2px",
					paddingRight: "0.75rem",
					opacity: 0.75,
					lineHeight: 0,
				},
				"& .text": {
					paddingTop: "0.1rem",
					fontWeight: "normal",
				},
			},
		},
	},
	palette: {
		colors: {
			// Black color palette
			black500: "#111827",
			// Gray color palette
			gray50: "#f9fafb", // Very light gray
			gray100: "#f3f4f6", // Light gray
			gray200: "#e5e7eb", // Soft gray
			gray300: "#d1d5db", // Medium light gray
			gray400: "#9ca3af", // Medium gray
			gray500: "#6b7280", // Dark gray
			gray600: "#4b5563", // Deep gray
			gray700: "#374151", // Charcoal gray
			gray800: "#1f2937", // Dark charcoal gray
			gray900: "#111827", // Almost black gray

			// Yellow color palette
			yellow50: "#FFFFE4", // Very light yellow
			yellow100: "#FFF4B5", // Light yellow
			yellow200: "#FFE884", // Soft yellow
			yellow300: "#FEDE4F", // Bright yellow
			yellow400: "#FDD326", // Vivid yellow
			yellow500: "#FFCB00", // Standard yellow
			yellow600: "#FFBE00", // Deep yellow
			yellow700: "#FFA900", // Dark yellow
			yellow800: "#FF9B00", // Rich yellow
			yellow900: "#FF7800", // Darker yellow-orange

			// Blue color palette
			blue50: "#BAD4EE", // Lightest blue
			blue100: "#97BEEC", // Soft blue
			blue200: "#6FACEE", // Sky blue
			blue300: "#4496E9", // Bright blue
			blue400: "#2884E5", // Vivid blue
			blue500: "#0B75E1", // Strong blue
			blue600: "#1267D1", // Deep blue
			blue700: "#1B55BE", // Dark blue
			blue800: "#1645AB", // Navy blue
			blue900: "#1627BB", // Darkest blue

			// Navy color palette
			navy50: "#959BC5", // Lightest shade
			navy100: "#7086B1", // Soft blue
			navy200: "#586C99", // Muted blue
			navy300: "#3A5484", // Medium navy
			navy400: "#264475", // Deep navy
			navy500: "#003264", // Rich navy
			navy600: "#002654", // Dark navy
			navy700: "#00163E", // Very dark navy
			navy800: "#00002A", // Almost black navy
			navy900: "#000017", // Deepest navy

			// Sky color palette
			sky200: "#D5F5FF", // Lightest sky blue
			sky300: "#AFE7FF", // Soft sky blue
			sky400: "#97DAFC", // Gentle sky blue
			sky500: "#87CEFA", // Standard sky blue
			sky600: "#7FBFEB", // Medium sky blue
			sky700: "#75AED9", // Deep sky blue
			sky800: "#6A9AC3", // Darker sky blue
			sky900: "#587BA4", // Deepest sky blue

			// Brand color palette
			brandDeepNavy: "#00264B", // A deep navy blue for a strong foundation
			brandNavy: "#003264", // A classic navy blue for primary elements
			brandCobalt: "#0B75E1", // A vibrant cobalt blue for accents
			brandSky: "#87Cefa", // A light sky blue for softer touches
			brandGold: "#ffcb00", // A bright gold for highlights and emphasis
			brandLightGrey: "#F5F5F5", // A light grey for backgrounds and subtle elements
			brandWhite: "#ffffff", // Pure white for clean spaces and contrast

			// Warning color palette
			warning50: "#FFFBEB", // Very light warning color (background)
			warning100: "#FEF3C7", // Light warning color
			warning200: "#FDE68A", // Medium light warning color
			warning300: "#FCD34D", // Medium warning color
			warning400: "#FBBF24", // Medium dark warning color
			warning500: "#F59E0B", // Dark warning color (primary warning)
			warning600: "#D97706", // Darker warning color
			warning700: "#B45309", // Even darker warning color
			warning800: "#92400E", // Very dark warning color
			warning900: "#78350F", // Deepest warning color (used for critical warnings)

			// Success color palette
			success50: "#F0FDF4", // Very light success color (background)
			success100: "#DCFCE7", // Light success color
			success200: "#BBF7D0", // Medium light success color
			success300: "#86EFAC", // Medium success color
			success400: "#4ADE80", // Medium dark success color
			success500: "#22C55E", // Dark success color (primary success)
			success600: "#16A34A", // Darker success color
			success700: "#15803D", // Even darker success color
			success800: "#166534", // Very dark success color
			success900: "#14532D", // Deepest success color (used for strong success messages)

			// Error color palette
			error50: "#FEF2F2", // Very light error color (background)
			error100: "#FEE2E2", // Light error color
			error200: "#FECACA", // Medium light error color
			error300: "#FCA5A5", // Medium error color
			error400: "#F87171", // Medium dark error color
			error500: "#EF4444", // Dark error color (primary error)
			error600: "#DC2626", // Darker error color
			error700: "#B91C1C", // Even darker error color
			error800: "#991B1B", // Very dark error color
			error900: "#7F1D1D", // Deepest error color (used for critical errors)

			divider: "#0000001F",
		},
		background: {
			default: "#ffffff",
		},
		primary: {
			main: NCS_PRIMARY,
			dark: darken(0.05, NCS_PRIMARY),
			light: transparentize(0.18, NCS_PRIMARY),
		},
		warning: {
			main: "#FFD346",
			contrastText: darken(0.65, "#FFD346"),
		},
		error: {
			main: "#E02104",
			dark: "#830000",
		},
		success: {
			main: "#5ed362",
		},
		text: {
			primary: "#393939",
			secondary: "#999",
		},
		cta: {
			main: NCS_PRIMARY,
			light: transparentize(0.18, NCS_PRIMARY),
		},
		chartColors: [
			"#0A3D53",
			"#78D237",
			"#561D7C",
			"#3299C1",
			"#FFAA46",
			"#005BA6",
			"#327E77",
			"#FFC61B",
			"#DB007A",
			"#8EB3C1",
			"#C12122",
		],
	},
	spacing: 16,
	tableBoxShadow: "0 3px 10px -1px rgba(0, 0, 0, 0.1)",
	typography: {
		fontSize: 16,
		fontFamily: NCS_FONT,
		body1: {
			fontSize: "1rem",
		},
		h1: {
			fontSize: "2rem",
			fontWeight: "bold",
		},
		h2: {
			fontSize: "1.563rem",
			fontWeight: "normal",
		},
		h3: {
			fontSize: "1.313rem",
			fontWeight: "normal",
		},
		h4: {
			fontSize: "1.125rem",
			fontWeight: "normal",
		},
		h5: {
			fontSize: "1rem",
			fontWeight: "bold",
		},
		h6: {
			fontSize: "0.75rem",
			fontWeight: "bold",
			textTransform: "uppercase",
			letterSpacing: "0.25px",
		},
	},
	transitions: {
		duration: {
			enteringScreen: 500,
		},
	},
	tabs: {
		navIconTop: "-2px",
	},
})
