import { FC, useState } from "react"

import { css, Theme } from "@emotion/react"
import { useHistory, useRouteMatch } from "react-router-dom"

import {
	initialCustomerPartQueryParams,
	useIsUser,
	usePartCategories,
	usePartChemicals,
	usePartManufacturers,
	usePartSystems,
	UserId,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	EventTracker,
	encodeUrlState,
	Heading,
	Link,
	TrackingEvent,
	useUrlState,
} from "@ncs/web-legos"

import type { PartSearchResultsUrlState } from "~/views/shop/part-search-results"

import { ShopBarNavSubmenu } from "./ShopBarNavSubmenu"

export type ShopBarNavUrlState = Pick<
	PartSearchResultsUrlState,
	"categories" | "manufacturers" | "systems" | "featured" | "clearance"
>

export const ShopBarNav: FC = () => {
	const history = useHistory()
	const isDb = useIsUser(UserId.DrivenBrands)
	const [brands] = usePartManufacturers()
	const [systems] = usePartSystems()
	const [categories] = usePartCategories()
	const [chemicals] = usePartChemicals()

	const [showBrandsSubmenu, setShowBrandsSubmenu] = useState(false)
	const [showSystemsSubmenu, setShowSystemsSubmenu] = useState(false)
	const [showCategoriesSubmenu, setShowCategoriesSubmenu] = useState(false)
	const [showChemicalsSubmenu, setShowChemicalsSubmenu] = useState(false)

	const [, { setUrlState }] = useUrlState<ShopBarNavUrlState>({
		categories: initialCustomerPartQueryParams.categories,
		manufacturers: initialCustomerPartQueryParams.manufacturers,
		systems: initialCustomerPartQueryParams.systems,
		featured: null,
		clearance: null,
	})
	const isOnSearchPage = useRouteMatch("/shop/search")

	const viewResult = (urlState: Partial<PartSearchResultsUrlState>) => {
		if (isOnSearchPage) {
			setUrlState({
				...initialCustomerPartQueryParams,
				...urlState,
			})
		} else {
			history.push({
				pathname: "/shop/search",
				search: encodeUrlState<PartSearchResultsUrlState>(urlState),
			})
		}
	}

	const onViewBrand = (brandId: string) => {
		setShowBrandsSubmenu(false)
		const urlState: Partial<PartSearchResultsUrlState> = {
			manufacturers: [brandId],
		}
		viewResult(urlState)
	}

	const onViewSystem = (systemId: string) => {
		setShowSystemsSubmenu(false)
		const urlState: Partial<PartSearchResultsUrlState> = {
			systems: [systemId],
		}
		viewResult(urlState)
	}

	const onViewCategory = (categoryId: string) => {
		setShowCategoriesSubmenu(false)
		const urlState: Partial<PartSearchResultsUrlState> = {
			categories: [categoryId],
		}
		viewResult(urlState)
	}

	return (
		<ul css={navListStyle}>
			<li
				className="has-children"
				onMouseEnter={() => setShowBrandsSubmenu(true)}
				onMouseLeave={() => setShowBrandsSubmenu(false)}
			>
				<span>Brands</span>
				<AnimatedEntrance
					show={showBrandsSubmenu}
					css={submenuContainerStyle}
					duration={200}
					delay={200}
				>
					<Heading>Brands</Heading>
					<ShopBarNavSubmenu
						items={(brands ?? []).map((brand) => ({
							label: brand.name ?? brand.code ?? brand.id,
							onClickValue: brand.id,
						}))}
						onClick={onViewBrand}
					/>
				</AnimatedEntrance>
			</li>
			<li
				className="has-children"
				onMouseEnter={() => setShowSystemsSubmenu(true)}
				onMouseLeave={() => setShowSystemsSubmenu(false)}
			>
				<span>Systems</span>
				<AnimatedEntrance
					show={showSystemsSubmenu}
					css={submenuContainerStyle}
					duration={200}
					delay={200}
				>
					<Heading>Systems</Heading>
					<ShopBarNavSubmenu
						items={(systems ?? []).map((system) => ({
							label: system.modelName,
							onClickValue: system.id,
						}))}
						onClick={onViewSystem}
					/>
				</AnimatedEntrance>
			</li>
			<li
				className="has-children"
				onMouseEnter={() => setShowCategoriesSubmenu(true)}
				onMouseLeave={() => setShowCategoriesSubmenu(false)}
			>
				<span>Categories</span>
				<AnimatedEntrance
					show={showCategoriesSubmenu}
					css={submenuContainerStyle}
					duration={200}
					delay={200}
				>
					<Heading>Categories</Heading>
					<ShopBarNavSubmenu
						items={(categories ?? []).map((system) => ({
							label: system.name ?? system.id,
							onClickValue: system.id,
						}))}
						onClick={onViewCategory}
					/>
				</AnimatedEntrance>
			</li>
			<li
				className="has-children"
				onMouseEnter={() => setShowChemicalsSubmenu(true)}
				onMouseLeave={() => setShowChemicalsSubmenu(false)}
			>
				<span>Chemicals</span>
				<AnimatedEntrance
					show={showChemicalsSubmenu}
					css={submenuContainerStyle}
					duration={200}
					delay={200}
				>
					<Heading>Chemicals</Heading>
					<ShopBarNavSubmenu
						columnCount={2}
						items={(chemicals ?? []).map((system) => ({
							label: system.name ?? system.id,
							onClickValue: system.id,
						}))}
						onClick={onViewCategory}
					/>
				</AnimatedEntrance>
			</li>
			<li>
				<button css={navLinkButton} onClick={() => viewResult({ featured: true })}>
					Featured Products
				</button>
			</li>
			{isDb === false && (
				<li>
					<EventTracker event={TrackingEvent.QUICK_ORDER}>
						<Link to="/shop/quick-order" underline={false}>
							<span>Quick Order</span>
						</Link>
					</EventTracker>
				</li>
			)}
		</ul>
	)
}

const navListStyle = (theme: Theme) => css`
	display: flex;
	column-gap: 0.5rem;
	margin-left: -1rem;
	> li {
		position: relative;
		font-size: 0.8rem;
		font-weight: bold;
		padding: 0.75rem 1rem 0.4rem 1rem;
		border-bottom: 5px solid ${theme.palette.primary.main};
		> span,
		a {
			color: white;
			background: none;
			padding: 0;
			border: 0;
			&:hover,
			&:focus {
				color: white;
			}
		}
		&.has-children {
			transition: border-color 300ms ease-out 100ms;
			> span {
				cursor: default;
			}
			&:hover {
				border-color: white;
			}
		}
	}
`
const submenuContainerStyle = css`
	position: absolute;
	z-index: 999;
	padding: 2rem 0 0 2rem;
	top: 0.65rem;
	left: -1rem;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
	z-index: 999;
	background: white;
`
const navLinkButton = css`
	color: white;
	border: 0;
	padding: 0;
	background: none;
	font-weight: 900;
`
