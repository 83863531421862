import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import axios, { AxiosError, Canceler } from "axios"
import qs from "query-string"
import { useQuery } from "react-query"
import { arrayUnwrap, arrayWrap, extractNumber, parseLinkHeader, unpythonify } from "@ncs/ts-utils"
import { useSetClientVersionFromHeaders } from "../contexts"
import { apiClient, buildUrl, preparePortalParams } from "../util"
import {
	ApiInfiniteGetQueryOptions,
	ApiPaginationGetQuery,
	ResponseDataWithPagination,
} from "./types"

export const usePaginationGetRequest = <Payload, QueryParams = {}>(
	endpoint: string | string[],
	optionsArg?: ApiInfiniteGetQueryOptions<Payload, QueryParams>
): ApiPaginationGetQuery<Payload> => {
	const defaultInfiniteGetQueryOptions: ApiInfiniteGetQueryOptions<Payload, QueryParams> = {
		manualPagination: false,
	}
	const options = {
		...defaultInfiniteGetQueryOptions,
		...optionsArg,
	}

	const mostRecentCallTime = useRef<number>()

	const [currentPage, setCurrentPage] = useState(1)
	const [resultPageCount, setResultPageCount] = useState(1)
	const [resultCountEstimate, setResultCountEstimate] = useState<number | null>(null)

	const setClientVersion = useSetClientVersionFromHeaders()

	// Make the axios cancel token.
	const CancelToken = useMemo(() => axios.CancelToken, [])
	const canceler = useRef<Canceler>()

	useEffect(() => {
		return (): void => {
			const cancelerRef = canceler

			if (cancelerRef.current) {
				cancelerRef.current()
			}
		}
	}, [])

	// Construct the URL.
	const endpointArray = useMemo(() => arrayWrap(endpoint), [endpoint])
	const endpointString = useMemo(() => `${endpointArray.join("/")}/`, [endpointArray])
	const preparedParams = useMemo(() => {
		return preparePortalParams(
			{ ...options.params },
			{ manualPagination: options.manualPagination }
		)
	}, [options.params, options?.manualPagination])

	// Now put the endpointArray together with the prepared params.
	const queryKey = useMemo(() => {
		const result: (string | object)[] = [...endpointArray]
		if (preparedParams) result.push(preparedParams)
		return result
	}, [endpointArray, preparedParams])

	/**
	 * This needs to be done here (rather than in `preparePortalParams`) because React Query wants
	 * a function to call that it can pass the page param to.
	 */
	const addPaginationToEndpoint = useCallback(
		(pageParam: string | number) => {
			// If you're handling the pagination keys manually, we'll assume they're in the prepared params.
			// Otherwise, we add them in here.

			const paramsWithPagination =
				options.manualPagination ? preparedParams : (
					{
						...preparedParams,
						pageSize: options.pageSize,
						page: pageParam,
					}
				)

			return `${endpointString}?${qs.stringify(paramsWithPagination, {
				skipEmptyString: true,
				skipNull: true,
				arrayFormat: "comma",
			})}`
		},
		[endpointString, options.pageSize, options.manualPagination, preparedParams]
	)

	const fetchPage = (page: number) => {
		setCurrentPage(page)
	}
	const query = useQuery<ResponseDataWithPagination<Payload>, AxiosError>(
		[queryKey, currentPage],
		({ pageParam = "1" }) => {
			const calledAt = Date.now()
			mostRecentCallTime.current = calledAt

			const finalEndpoint = buildUrl(addPaginationToEndpoint(currentPage))

			return apiClient
				.get(finalEndpoint, {
					cancelToken: new CancelToken((c) => {
						canceler.current = c
					}),
				})
				.then((response) => {
					// Keep client version context up to date with the latest header.
					setClientVersion(response.headers)

					const data =
						options?.mapper ?
							options.mapper(response.data)
						:	unpythonify(response.data)

					const links = parseLinkHeader(response.headers.link)
					const nextPageNum = links?.next?.page ?? null

					// Only update result count data if this is the most recent call.
					if (calledAt === (mostRecentCallTime?.current ?? 0)) {
						const parsedUrl = qs.parseUrl(finalEndpoint)
						const requestPageNumber =
							extractNumber(arrayUnwrap(parsedUrl.query.page)) || 1
						const requestPageSize =
							extractNumber(arrayUnwrap(parsedUrl.query.pageSize)) ||
							extractNumber(links?.last?.pageSize ?? links?.first?.pageSize) ||
							1
						const pageCount =
							extractNumber(links?.last?.page) || requestPageNumber || 1
						const responseDataLength = Array.isArray(data) ? data.length : 1

						let countEstimate = 0

						if (requestPageNumber === pageCount) {
							countEstimate = responseDataLength + (pageCount - 1) * requestPageSize
						} else {
							countEstimate = pageCount * requestPageSize
						}
						setResultCountEstimate(countEstimate)
						setResultPageCount(pageCount)
						setCurrentPage(requestPageNumber)
					}
					return {
						data,
						nextPageNum,
					}
				})
		},
		{ cacheTime: 0 }
	)
	return {
		data: (query?.data?.data ?? []) as any,
		isLoading: query.isLoading,
		isFetching: query.isFetching,
		hasNextPage: currentPage < resultPageCount,
		hasPreviousPage: currentPage > 1,
		resultCountEstimate,
		resultPageCount,
		currentPage,
		queryKey,
		rawQuery: query as any,
		fetchPage,
	}
}
