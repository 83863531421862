import React from "react"
import { css } from "@emotion/react"
import { formatCurrency } from "@ncs/ts-utils"
import { Box, BoxProps, SkeletonRows } from "@ncs/web-legos"
export interface OrderSummaryRowProps extends BoxProps {
	label: string | React.ReactNode
	amount: string | number | null
	isLoading?: boolean
}

export const OrderSummaryRow: React.FC<OrderSummaryRowProps> = ({
	label,
	amount,
	isLoading,
	...rest
}) => {
	return (
		<Box display="flex" justifyContent="space-between" mb={0.75} {...rest}>
			<span>{label}</span>
			<span css={amountStyle}>
				{isLoading ?
					<SkeletonRows width={4} rows={1} />
				: typeof amount === "number" ?
					formatCurrency(amount)
				:	amount}
			</span>
		</Box>
	)
}

const amountStyle = css`
	font-weight: bold;
`
