import React, { memo } from "react"
import { css, Theme } from "@emotion/react"
import { useAuth } from "@ncs/ncs-api"
import { Box, Paragraph, isFeatureFlagOn, Flag } from "@ncs/web-legos"
import { Logo } from "../Logo"
import { NcsSocial } from "../NcsSocial"
import BottomBar from "./BottomBar"
import FooterNavigation from "./FooterNavigation"
import ContactUs from "./ContactUs"

const SiteFooter: React.FC = memo(() => {
	const { user } = useAuth()
	const loginWallAllowAnonymous = isFeatureFlagOn(Flag.LOGIN_WALL)

	return (
		<footer css={footerStyle}>
			<div css={innerFooterStyle}>
				<div css={addressStyle}>
					<Logo company="ncsWhite" width={"8rem"} />
					<Paragraph color="white" mb={1} mt={2}>
						1500 SE 37th St.
					</Paragraph>
					<Paragraph color="white">Grimes, IA 50111</Paragraph>
				</div>
				<div css={footerNavigationStyle}>
					{(user || loginWallAllowAnonymous) && <FooterNavigation />}
				</div>
				<div css={contactContainerStyle}>
					<ContactUs />
					<Box mt={2}>
						<NcsSocial customColor="#87CEFA" />
					</Box>
				</div>
			</div>
			<BottomBar />
		</footer>
	)
})

export default SiteFooter

const footerStyle = (theme: Theme) => css`
	background: ${theme.palette.primary.dark};
`

const innerFooterStyle = (theme: Theme) => css`
	display: flex;
	padding: 4rem 2rem;
	${theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`

const footerNavigationStyle = (theme: Theme) => css`
	margin-left: 12rem;
	${theme.breakpoints.down("sm")} {
		margin-left: 0;
	}
`

const addressStyle = css`
	margin-top: 1rem;
`

const contactContainerStyle = (theme: Theme) => css`
	margin-left: 12rem;
	${theme.breakpoints.down("sm")} {
		margin-left: 0;
	}
	a {
		color: #ffffff;
		text-decoration: none;
		font-weight: 500;

		&:focus {
			outline: 2px dashed #87cefa;
			outline-offset: 2px;
		}
	}
`
