import "react-responsive-carousel/lib/styles/carousel.min.css"

import React, { ComponentProps } from "react"

import { css } from "@emotion/react"
import { Carousel as ReactResponsiveCarousel } from "react-responsive-carousel"

import { withCssUnit } from "@ncs/ts-utils"

export type ReactResponsiveCarouselProps = Partial<ComponentProps<typeof ReactResponsiveCarousel>>

export interface CarouselProps extends ReactResponsiveCarouselProps {
	/** Specify the height of the carousel. The width is 100%, and the images will
	 * fill accordingly. */
	height?: string | number
}

export const Carousel: React.FC<CarouselProps> = ({ height = "455px", children, ...rest }) => {
	const style = css`
		.slide > div {
			height: ${withCssUnit(height)};
		}
	`

	return (
		<ReactResponsiveCarousel css={style} autoPlay infiniteLoop {...rest}>
			{children}
		</ReactResponsiveCarousel>
	)
}
