import React, { useState } from "react"

import { css, Theme } from "@emotion/react"

import {
	CustomerPart,
	CustomerPartQueryParams,
	initialCustomerPartQueryParams,
	useCustomerParts,
} from "@ncs/ncs-api"
import { arrayWrap, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Flag,
	GridContainer,
	GridItem,
	Icon,
	LoadingSpinner,
	Paragraph,
	ResultCountSummary,
	useFeatureFlag,
	useScreenSizeMatch,
	useUrlState,
} from "@ncs/web-legos"

import { AddedToCartModal, PageContentWrapper } from "~/shared-components"

import {
	ActiveFilters,
	PartCard,
	PartSearchSidebar,
	PartSort,
	SearchFilterModal,
} from "./components"
import { PartSearchResultsEcomm } from "./PartSearchResultsEcomm"

export type PartSearchResultsUrlState = Omit<
	CustomerPartQueryParams,
	"categories" | "manufacturers" | "systems"
> & {
	// We're changing these types so that the backend doesn't need to JSON stringify these.
	manufacturers: string | number | (string | number)[]
	categories: string | number | (string | number)[]
	systems: string | number | (string | number)[]
}

export const PartSearchResults: React.FC = () => {
	const Component = useFeatureFlag(Flag.HOME, PartSearchResultsOld, PartSearchResultsEcomm)
	return <Component />
}

export const PartSearchResultsOld: React.FC = () => {
	const screenIsTiny = useScreenSizeMatch("xs")
	const [addedPart, setAddedPart] = useState<CustomerPart | null>(null)
	const [params] = useUrlState<PartSearchResultsUrlState>(initialCustomerPartQueryParams)

	const {
		data: parts,
		isLoading,
		isFetching,
		resultCountEstimate,
		fetchNextPage,
		hasNextPage,
	} = useCustomerParts({
		params: {
			...params,
			categories: arrayWrap(params.categories).map((c) => String(c)),
			manufacturers: arrayWrap(params.manufacturers).map((m) => String(m)),
			systems: arrayWrap(params.systems).map((s) => String(s)),
		},
		pageSize: 25,
		removeChildren: true,
	})
	const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)

	return (
		<PageContentWrapper
			title="Product Search"
			breadcrumbs={[{ name: "Shop", to: "/shop" }, { name: "Search" }]}
		>
			<div css={contentContainer}>
				{!screenIsTiny && (
					<div>
						<PartSearchSidebar />
					</div>
				)}

				<div>
					{screenIsTiny && (
						<Box
							display="flex"
							justifyContent="flex-end"
							mb={2}
							flexDirection="column"
							alignItems="flex-end"
						>
							<Button icon="filter" onClick={() => setShowSearchFilterModal(true)}>
								Search Filters
							</Button>
						</Box>
					)}

					<div css={searchResultsStatusHeaderStyle}>
						<div className="result-count">
							<Paragraph mt={0.33}>
								{!!resultCountEstimate && (
									<>
										{hasNextPage && "Approximately "}
										<strong>{formatNumber(resultCountEstimate)}</strong>{" "}
										results found
									</>
								)}
							</Paragraph>
						</div>

						<div className="active-filters">
							<ActiveFilters />
						</div>

						<div className="sort-control">
							<PartSort />
						</div>
					</div>

					{isLoading && <LoadingSpinner text="Searching..." py={10} />}

					<GridContainer>
						{parts.map((part) => (
							<GridItem key={part.id} sm={12} md={6} mb={2}>
								<PartCard
									part={part}
									onAddToCart={() => setAddedPart(part)}
									css={partCardStyle}
								/>
							</GridItem>
						))}
					</GridContainer>

					{!isLoading && parts.length > 0 && (
						<Box mt={3} textAlign="center">
							<ResultCountSummary
								currentTotal={parts.length}
								totalResults={resultCountEstimate}
								resultsAreApproximate={hasNextPage}
								mb={1}
							/>

							{hasNextPage && (
								<Button
									variant="secondary-cta"
									onClick={() => fetchNextPage()}
									isLoading={isFetching}
								>
									Load more results
								</Button>
							)}
						</Box>
					)}

					{!isLoading && parts.length === 0 && (
						<Box my={8} textAlign="center">
							<Icon icon="search" fontSize="3rem" color="gray" />
							<Paragraph mt={2} color="secondary">
								Sorry, no results were found. Try a different search?
							</Paragraph>
						</Box>
					)}
				</div>
			</div>

			<AddedToCartModal
				isOpen={!!addedPart}
				partId={addedPart?.id}
				onClose={() => setAddedPart(null)}
			/>
			<SearchFilterModal
				isOpen={showSearchFilterModal}
				onClose={() => setShowSearchFilterModal(false)}
			/>
		</PageContentWrapper>
	)
}

const contentContainer = (theme: Theme) => css`
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 3rem;
	${theme.breakpoints.down("xs")} {
		grid-template-columns: 1fr;
		column-gap: 0;
	}
`
const partCardStyle = css`
	height: 100%;
`
const searchResultsStatusHeaderStyle = (theme: Theme) => css`
	display: grid;
	margin-bottom: 1rem;
	grid-gap: 1rem;
	max-width: 100%;
	overflow: hidden;
	.result-count {
		grid-area: resultCount;
	}
	.active-filters {
		grid-area: activeFilters;
	}
	.sort-control {
		grid-area: sortControl;
	}
	grid-template-columns: minmax(20rem, auto) 1fr auto;
	grid-template-areas:
		"resultCount . sortControl"
		"activeFilters activeFilters activeFilters";
	${theme.breakpoints.down("xs")} {
		grid-template-areas:
			"sortControl sortControl sortControl"
			"activeFilters activeFilters activeFilters"
			"resultCount resultCount resultCount";
	}
`
