import { FC, useState } from "react"
import { Link } from "react-router-dom"
import { css, Theme } from "@emotion/react"
import { useLocation } from "react-router-dom"
import { SupportIssueModal } from "~/shared-components"
import { APPLICATION, useAuth, useIsUser, UserId, useUserCanUse } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Paragraph,
	useScreenSizeMatch,
	LogoutIcon,
	UserIcon,
	encodeUrlState,
} from "@ncs/web-legos"
import { LoginUrlState } from "~/views/auth/login"
import { MobileAccountMenuModal } from "../MobileAccountMenuModal"

export const ShopBarUserWelcomeEcomm: FC = () => {
	const auth = useAuth()
	const [showTicketModal, setShowTicketModal] = useState(false)
	const screenIsMediumUp = useScreenSizeMatch("md", "up")
	const canUseConnectivity = useUserCanUse(APPLICATION.Connectivity)
	const canUseEquipment = useUserCanUse(APPLICATION.Equipment)
	const canUseDispatch = useUserCanUse([APPLICATION.CreateDispatch, APPLICATION.SearchDispatch])
	const isDb = useIsUser(UserId.DrivenBrands)
	const [showAcountSubmenu, setShowAcountSubmenu] = useState(false)
	const [showModalAcountSubmenu, setShowModalAcountSubmenu] = useState(false)

	const { pathname, search } = useLocation()

	const loginPath = () => {
		const redirect = `${pathname}${search}`
		const loggedIn = !(auth.user === null)

		if (loggedIn) {
			return "/auth/login"
		} else {
			const redirectUrlState = encodeUrlState<LoginUrlState>({ redirect })
			return "/auth/login" + redirectUrlState
		}
	}

	const handleMouseEnter = () => {
		if (screenIsMediumUp) {
			setShowAcountSubmenu(true)
		}
	}

	const accountMenuHandler = () => {
		if (!screenIsMediumUp) {
			setShowModalAcountSubmenu(true)
		}
	}

	const closeAccountModalHandler = () => {
		if (!screenIsMediumUp) {
			setShowModalAcountSubmenu(false)
		}
	}

	return (
		<>
			<SupportIssueModal
				isOpen={showTicketModal}
				onClose={() => setShowTicketModal(false)}
			/>
			{auth.user ?
				<Box display="flex" alignItems="center">
					<div onMouseEnter={handleMouseEnter}>
						<span css={userWelcomeStyle}>
							<Box display="flex" alignItems="center" columnGap={0.75}>
								<UserIcon onClick={accountMenuHandler} css={userIconStyle} />

								{screenIsMediumUp && (
									<Box display="flex" flexDirection="column" flexShrink={0}>
										<Paragraph bold small color="white" css={firstnameStyle}>
											{auth.user.name.split(" ")[0]}
										</Paragraph>
									</Box>
								)}
							</Box>
						</span>
					</div>
					<div
						onMouseLeave={() => {
							setShowAcountSubmenu(false)
						}}
					>
						<AnimatedEntrance
							show={showAcountSubmenu}
							css={submenuContainerStyle}
							duration={200}
							delay={200}
						>
							{auth.user && <div css={usernameStyle}>{auth.user.name}</div>}
							<ul css={accountMenuStyle}>
								{!isDb && (
									<li css={separatorStyle}>
										<Link to="/account">My Account</Link>
									</li>
								)}
								{!isDb && canUseDispatch && (
									<li>
										<Link to="/service-requests">My Service Requests</Link>
									</li>
								)}
								{!isDb && canUseEquipment && (
									<li>
										<Link to="/my-equipment">
											<div>My Equipment</div>
										</Link>
									</li>
								)}
								{canUseConnectivity && (
									<li>
										<Link to="/connectivity">Connectivity</Link>
									</li>
								)}
								<li css={separatorStyle}>
									<button
										onClick={() => setShowTicketModal(true)}
										css={buttonStyle}
									>
										Contact Us
									</button>
								</li>
								<li css={separatorStyle}>
									<Link to="/auth/logout">
										<LogoutIcon />
										Logout
									</Link>
								</li>
							</ul>
						</AnimatedEntrance>
					</div>
				</Box>
			:	<Box display="flex" alignItems="center">
					<div onMouseEnter={handleMouseEnter}>
						<span css={userWelcomeStyle}>
							<Box display="flex" alignItems="center" columnGap={0.75}>
								{screenIsMediumUp && (
									<UserIcon onClick={accountMenuHandler} css={userIconStyle} />
								)}

								<Box display="flex" flexDirection="column" flexShrink={0}>
									<Link to={loginPath()}>
										<Paragraph bold small color="white" css={firstnameStyle}>
											Login / Signup
										</Paragraph>
									</Link>
								</Box>
							</Box>
						</span>
					</div>
				</Box>
			}
			<MobileAccountMenuModal
				isOpen={showModalAcountSubmenu}
				onClose={closeAccountModalHandler}
			/>
		</>
	)
}

const buttonStyle = css`
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	font: inherit;
	color: inherit;
	cursor: pointer;
	outline: none;
	text-align: inherit;
	display: inline-block;
`

const userIconStyle = (theme: Theme) => css`
	cursor: pointer;
	height: 36px;
	width: 36px;
	display: inline-block;
	padding-left: 6px;
	padding-top: 4px;
`

const firstnameStyle = css`
	max-width: 9em;
	text-overflow: ellipsis;
	overflow: hidden;
	color: #fff;
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding-top: 0px;
	padding-bottom: 0px;
	margin-top: 9px;
	margin-bottom: 11px;
`

const usernameStyle = css`
	padding-left: 24px;
	padding-top: 36px;
	padding-bottom: 13px;
	font-weight: 900;
	color: #111827;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`

const submenuContainerStyle = css`
	position: absolute;
	z-index: 999;
	top: 2rem;
	width: 278px;
	right: 0rem;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	z-index: 999;
	background: white;
	border-radius: 8px;
	overflow: hidden;
	& * {
		overflow: hidden;
	}

	& li:last-child {
		padding-bottom: 24px;
	}
`

const userWelcomeStyle = css`
	float: right;
	flex-shrink: 0;
	cursor: pointer;
	span {
		font-size: 0.8rem;
		color: white;
		font-weight: normal;
	}
`

const separatorStyle = (theme: Theme) => css`
	border-top: 1px solid ${theme.palette.colors.divider};
`

const accountMenuStyle = (theme: Theme) => css`
	break-inside: avoid-column;

	li {
		a,
		button {
			display: flex;
			width: 100%;
			height: 40px;
			padding-left: 24px;
			padding-right: 24px;
			padding-top: 8px;
			padding-bottom: 14px;
			text-align: left;
			text-decoration: none;
			color: ${theme.palette.colors.gray900};
			font-size: 14px;
			font-weight: 400;
			line-height: normal;

			&:hover path {
				fill: white;
			}
			&:hover {
				background: ${theme.palette.colors.blue500};
				color: white;
			}
			svg {
				margin-right: 0.5em;
				margin-top: 4px;
			}
		}

		div {
			height: 18px;
		}
	}
`
