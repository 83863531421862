import React, { memo, useCallback, useMemo } from "react"
import { css } from "@emotion/react"
import {
	CustomerPartQueryParams,
	initialCustomerPartQueryParams,
	usePartCategories,
	usePartManufacturers,
	usePartSystems,
} from "@ncs/ncs-api"
import { arrayWrap, SubTypeIncluded, toggleArrayItem } from "@ncs/ts-utils"
import { AnimatedEntrance, Chip, useUrlState } from "@ncs/web-legos"
import { priceRanges } from "../part-search-results-util"
import { PartSearchResultsUrlState } from "../PartSearchResults"

export const ActiveFiltersEcomm: React.FC = memo(() => {
	const [manufacturers] = usePartManufacturers()
	const [systems] = usePartSystems()
	const [categories] = usePartCategories()
	const [partQueryParams, { setUrlState }] = useUrlState<PartSearchResultsUrlState>(
		initialCustomerPartQueryParams
	)

	const deleteFeaturedFilter = useCallback(() => {
		setUrlState((prev) => ({
			...prev,
			featured: null,
		}))
	}, [setUrlState])

	const deletePriceFilter = useCallback(() => {
		setUrlState((prev) => ({
			...prev,
			price_Gte: null,
			price_Lt: null,
		}))
	}, [setUrlState])

	const deleteArrayFilter = useCallback(
		(idToRemove: string, type: (typeof arrayFiltersToShow)[number]) => {
			setUrlState((prev) => ({
				...prev,
				[type]: toggleArrayItem(
					idToRemove,
					arrayWrap(partQueryParams[type]).map((itemId) => String(itemId))
				),
			}))
		},
		[setUrlState, partQueryParams]
	)

	// These are the query params that are arrays of things, like arrays of systems to include.
	const arrayFiltersToShow: (keyof SubTypeIncluded<CustomerPartQueryParams, string[]>)[] =
		useMemo(() => ["manufacturers", "systems", "categories"], [])

	const priceChipLabel = useMemo(() => {
		const currentMin = partQueryParams.price_Gte
		const currentMax = partQueryParams.price_Lt

		return priceRanges.find(
			(r) =>
				(r.min ? String(r.min) : null) === currentMin &&
				(r.max ? String(r.max) : null) === currentMax
		)?.text
	}, [partQueryParams.price_Lt, partQueryParams.price_Gte])

	const getArrayChipLabel = useCallback(
		(id: string, filterType: (typeof arrayFiltersToShow)[number]) => {
			switch (filterType) {
				case "manufacturers": {
					return `Brand: ${
						(manufacturers ?? []).find((b) => String(b.id) === id)?.name ?? ""
					}`
				}
				case "categories": {
					return `Category: ${
						(categories ?? []).find((c) => String(c.id) === id)?.name ?? ""
					}`
				}
				case "systems": {
					return `System: ${
						(systems ?? []).find((s) => String(s.id) === id)?.modelName ?? ""
					}`
				}
				default:
					return "Unknown filter type"
			}
		},
		[manufacturers, categories, systems]
	)

	// The array filters are a bit trickier, so we'll do them up here by themselves.
	const activeArrayFilters = useMemo(() => {
		return arrayFiltersToShow.map((filter) =>
			arrayWrap(partQueryParams[filter]).map((id) => (
				<li key={`${filter}${id}`}>
					<AnimatedEntrance show>
						<Chip
							label={getArrayChipLabel(id.toString(), filter) || ""}
							onDelete={() => deleteArrayFilter(id.toString(), filter)}
							title="Remove filter"
						/>
					</AnimatedEntrance>
				</li>
			))
		)
	}, [partQueryParams, arrayFiltersToShow, getArrayChipLabel, deleteArrayFilter])

	return (
		<ul css={chipListStyle}>
			{partQueryParams.featured && (
				<li>
					<AnimatedEntrance show>
						<Chip label="Trending" onDelete={deleteFeaturedFilter} />
					</AnimatedEntrance>
				</li>
			)}
			{(partQueryParams.price_Gte || partQueryParams.price_Lt) && (
				<li>
					<AnimatedEntrance show>
						<Chip
							label={`Price: ${priceChipLabel}`}
							onDelete={deletePriceFilter}
							title="Remove filter"
						/>
					</AnimatedEntrance>
				</li>
			)}
			{activeArrayFilters}
		</ul>
	)
})

const chipListStyle = css`
	display: flex;
	flex-wrap: wrap;
	li {
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
	}
`
