import React, { memo, useState } from "react"
import { css } from "@emotion/react"
import { CustomerPart, useCustomerPart } from "@ncs/ncs-api"
import { CardProps } from "@ncs/web-legos"

import { PartCardEcomm } from "~/views/shop/part-search-results/components"
import { AddedToCartModalEcomm } from "./AddedToCartModalEcomm"

interface PartCardProps extends CardProps {
	partId: string
	onAddToCart?: (partId: string) => void
}

export const PartPreviewCardEcomm: React.FC<PartCardProps> = memo(
	({ partId, onAddToCart, ...rest }) => {
		const [part, loading] = useCustomerPart(partId)
		const [addedPart, setAddedPart] = useState<CustomerPart | null>(null)

		if (loading || !part) {
			return null
		}

		return (
			<>
				<PartCardEcomm
					part={part}
					onAddToCart={() => setAddedPart(part)}
					css={partCardStyle}
					{...rest}
				/>
				<AddedToCartModalEcomm
					isOpen={!!addedPart}
					partId={addedPart?.id}
					onClose={() => setAddedPart(null)}
				/>
			</>
		)
	}
)

const partCardStyle = css`
	height: 100%;
`
