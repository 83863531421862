import React from "react"
export interface CartIconProps {
	color?: string
}

export const CartIcon: React.FC<CartIconProps> = ({ color, ...rest }) => {
	return (
		<span {...rest}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				<path
					d="M22.6904 5.81059C22.5855 5.67253 22.4501 5.56059 22.2948 5.48351C22.1395 5.40642 21.9684 5.36628 21.795 5.36621H5.69161L4.87879 2.44027C4.81302 2.20336 4.67145 1.99451 4.47573 1.84569C4.28001 1.69687 4.04092 1.61627 3.79504 1.61621H1.92004C1.62168 1.61621 1.33553 1.73474 1.12455 1.94572C0.91357 2.15669 0.795044 2.44284 0.795044 2.74121C0.795044 3.03958 0.91357 3.32573 1.12455 3.53671C1.33553 3.74768 1.62168 3.86621 1.92004 3.86621H2.94004L6.15567 15.4434C6.3105 15.9959 6.64155 16.4827 7.09846 16.8298C7.55537 17.1769 8.11313 17.3652 8.68692 17.3662H17.9513C18.5251 17.3652 19.0828 17.1769 19.5398 16.8298C19.9967 16.4827 20.3277 15.9959 20.4825 15.4434L22.8854 6.79215C22.9308 6.62459 22.9368 6.44878 22.903 6.27849C22.8692 6.1082 22.7964 5.94806 22.6904 5.81059ZM18.3075 14.8415C18.2859 14.9197 18.2394 14.9887 18.1752 15.0382C18.1109 15.0877 18.0324 15.1151 17.9513 15.1162H8.68504C8.60314 15.1161 8.52351 15.0892 8.45834 15.0396C8.39316 14.99 8.34601 14.9204 8.32411 14.8415L6.31692 7.61621H20.3147L18.3075 14.8415ZM10.17 20.3662C10.17 20.7371 10.0601 21.0996 9.85405 21.4079C9.64802 21.7162 9.35519 21.9566 9.01258 22.0985C8.66996 22.2404 8.29296 22.2775 7.92925 22.2052C7.56553 22.1328 7.23144 21.9543 6.96922 21.692C6.707 21.4298 6.52842 21.0957 6.45607 20.732C6.38372 20.3683 6.42086 19.9913 6.56277 19.6487C6.70468 19.3061 6.94501 19.0132 7.25335 18.8072C7.56169 18.6012 7.9242 18.4912 8.29504 18.4912C8.79232 18.4912 9.26924 18.6888 9.62087 19.0404C9.9725 19.392 10.17 19.8689 10.17 20.3662ZM19.92 20.3662C19.92 20.7371 19.8101 21.0996 19.604 21.4079C19.398 21.7162 19.1052 21.9566 18.7626 22.0985C18.42 22.2404 18.043 22.2775 17.6792 22.2052C17.3155 22.1328 16.9814 21.9543 16.7192 21.692C16.457 21.4298 16.2784 21.0957 16.2061 20.732C16.1337 20.3683 16.1709 19.9913 16.3128 19.6487C16.4547 19.3061 16.695 19.0132 17.0033 18.8072C17.3117 18.6012 17.6742 18.4912 18.045 18.4912C18.5423 18.4912 19.0192 18.6888 19.3709 19.0404C19.7225 19.392 19.92 19.8689 19.92 20.3662Z"
					fill={color ?? "#00050A"}
				/>
			</svg>
		</span>
	)
}
