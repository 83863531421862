import { useMemo } from "react"

import { CamelToSnakeCaseNested, pythonify, unpythonify } from "@ncs/ts-utils"

import { useAuth } from "../../contexts"
import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPostMutation,
	useDeleteRequest,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
	usePaginationGetRequest,
	ApiPaginationGetQuery,
} from "../../request-hooks"
import { apiClient, buildUrl } from "../../util"
import { InventoryUrlPaths } from "../inventory"
import {
	BrandCard,
	BrandCardOrderPost,
	BrandCardPatch,
	BrandCardPost,
	BrandCardQueryParams,
	CarouselImage,
	CarouselImageOrderPost,
	CarouselImagePatch,
	CarouselImagePost,
	CarouselImageQueryParams,
	CreateCustomerIssuePost,
	CustomerManufacturer,
	CustomerPart,
	CustomerPartQueryParams,
	CustomerSystem,
	IssueReason,
	OnlinePartDetails,
	OnlinePartRelations,
	OnlinePartRelationsQueryParams,
	PartCategory,
	PartCategoryPatch,
	PartCategoryPost,
	PointOfSaleUrlPaths,
	PosPart,
	PosPartPatch,
	PosPartPost,
	PosPartQueryParams,
	ProductCatalog,
	ProductCatalogDetails,
	ProductCatalogPartDelete,
	ProductCatalogPartPost,
	ProductCatalogPatch,
	ProductCatalogPost,
	ProductCatalogUserDelete,
	ProductCatalogUserPOst,
	ShippitResponse,
	UserBrandCardsPost,
} from "./types"

export const usePosPart = (
	id: string | null,
	options?: ApiGetQueryOptions<PosPart, PosPartQueryParams>
): ApiGetQuery<PosPart> => {
	return useGetRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Parts, id ?? ""], {
		...options,
		queryConfig: {
			...options?.queryConfig,
			enabled: !!id,
		},
	})
}

export const defaultPosPartQueryParams: PosPartQueryParams = {
	search: null,
	ordering: null,
	category: null,
	part: null,
	active: null,
	featured: null,
	clearance: null,
	parent: null,
	child: null,
}

export const usePosParts = (
	options?: ApiInfiniteGetQueryOptions<PosPart, PosPartQueryParams>
): ApiInfiniteGetQuery<PosPart> => {
	return useInfiniteGetRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Parts], {
		...options,
		params: {
			...defaultPosPartQueryParams,
			...options?.params,
		},
	})
}

export const useCreatePosPart = (): ((partData: PosPartPost) => Promise<void>) => {
	const formDataPost = usePostRequest<FormData>(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Parts],
		{
			keyToInvalidate: [PointOfSaleUrlPaths.PointOfSale, InventoryUrlPaths.Inventory],
		}
	)

	// Convert the typed `PosPartPost` data into FormData.
	// Doing this here so the hook can preserve typing.
	const partDataPost = async (partData: PosPartPost): Promise<void> => {
		const form = new FormData()
		// Don't try to pythonify the image file (their key is "file").
		const pythonifiedPartData = pythonify(partData, "file")
		Object.entries(pythonifiedPartData).forEach(([key, value]) => {
			if (value) {
				form.append(key, value as string | Blob)
			}
		})

		await formDataPost(form)
	}

	return partDataPost
}

export const useUpdatePosPart = (): ApiPatchMutation<PosPartPatch> => {
	return usePatchRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Parts], {
		keyToInvalidate: [PointOfSaleUrlPaths.PointOfSale, InventoryUrlPaths.Inventory],
	})
}

export const useUpdatePosPartImage = (): ApiPatchMutation<FormData> => {
	return usePatchRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Parts], {
		keyToInvalidate: [PointOfSaleUrlPaths.PointOfSale, InventoryUrlPaths.Inventory],
	})
}

export const useDeletePosPart = (): ApiDeleteMutation => {
	return useDeleteRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Parts], {
		keyToInvalidate: [PointOfSaleUrlPaths.PointOfSale, InventoryUrlPaths.Inventory],
	})
}

export const useCustomerPart = (
	onlinePartId?: string | null,
	options?: ApiGetQueryOptions<CustomerPart>
): ApiGetQuery<CustomerPart> => {
	const { isPrivilegedDbUser } = useAuth()

	return useGetRequest(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.CustomerParts, onlinePartId ?? ""],
		{
			...options,
			params: {
				...options?.params,
				pdbu: isPrivilegedDbUser ? true : undefined,
			},
			queryConfig: {
				enabled: !!onlinePartId,
				retry: 2,
				...options?.queryConfig,
			},
		}
	)
}

export const useCustomerParts = (
	options?: ApiInfiniteGetQueryOptions<CustomerPart, CustomerPartQueryParams> & {
		removeChildren?: boolean
	}
): ApiInfiniteGetQuery<CustomerPart> => {
	const { isPrivilegedDbUser } = useAuth()

	const result = useInfiniteGetRequest<CustomerPart, CustomerPartQueryParams & { pdbu?: true }>(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.CustomerParts],
		{
			...options,
			params: {
				...options?.params,
				pdbu: isPrivilegedDbUser ? true : undefined,
			},
		}
	)

	const preparedData = useMemo(() => {
		return options?.removeChildren ? result.data.filter((part) => !part.isChild) : result.data
	}, [options?.removeChildren, result.data, result.resultPageCount])

	return {
		...result,
		data: preparedData,
		resultCountEstimate:
			result.resultCountEstimate == null ?
				null
			:	result.resultCountEstimate - (result.data.length - preparedData.length),
	}
}

export const useCustomerPartsEcomm = (
	options?: ApiInfiniteGetQueryOptions<CustomerPart, CustomerPartQueryParams> & {
		removeChildren?: boolean
	}
): ApiPaginationGetQuery<CustomerPart> => {
	const { isPrivilegedDbUser } = useAuth()

	const result = usePaginationGetRequest<
		CustomerPart,
		CustomerPartQueryParams & { pdbu?: true }
	>([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.CustomerParts], {
		...options,
		params: {
			...options?.params,
			pdbu: isPrivilegedDbUser ? true : undefined,
		},
	})

	return {
		...result,
	}
}

export const useOnlinePartRelations = (
	onlinePartId: string | null | undefined,
	options?: ApiGetQueryOptions<OnlinePartRelations, OnlinePartRelationsQueryParams>
): ApiGetQuery<OnlinePartRelations> => {
	return useGetRequest<OnlinePartRelations, OnlinePartRelationsQueryParams>(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.PartRelationship],
		{
			...options,
			queryConfig: {
				...options?.queryConfig,
				enabled: !!onlinePartId, // Call will error if `onlinePart` is not present.
			},
			params: {
				onlinePart: onlinePartId ?? "",
				...options?.params,
			},
		}
	)
}

export const useOnlinePartDetails = (
	onlinePartId?: string | null,
	options?: ApiGetQueryOptions<OnlinePartDetails>
): ApiGetQuery<OnlinePartDetails> => {
	return useGetRequest(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.PartDetail, onlinePartId ?? ""],
		{
			...options,
			queryConfig: {
				...options?.queryConfig,
				enabled: !!onlinePartId,
			},
		}
	)
}

export const initialCustomerPartQueryParams: CustomerPartQueryParams = {
	search: null,
	ordering: "title",
	manufacturers: [],
	systems: [],
	categories: [],
	price_Gte: null,
	price_Lt: null,
	featured: null,
	clearance: null,
	onlinePartId: [],
	chemicals: [],
}

export const usePartManufacturers = (): ApiGetQuery<CustomerManufacturer[]> => {
	return useGetRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Manufacturers], {
		queryConfig: {
			refetchOnWindowFocus: false,
		},
		mapper: (data) => {
			return unpythonify<CustomerManufacturer[]>([
				...data,
				// We're adding in Vacutech and TSS here manually. Remove this when it gets actually
				// added portal side, and update the hardcoded brand link on the Shope page.
				{
					id: 1000,
					code: "Vacutech",
					name: "Vacutech",
				},
				{
					id: 1001,
					code: "TSS",
					name: "TSS",
				},
			]).sort((a, b) => {
				// Since we're adding more items in, resort the array.
				if (a.name === b.name) return 0
				if (a.name === null) return 1
				if (b.name === null) return -1
				return a.name < b.name ? -1 : 1
			})
		},
	})
}

export const usePartCategories = (): ApiGetQuery<PartCategory[]> => {
	return useGetRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Categories])
}

export const usePartChemicals = (): ApiGetQuery<PartCategory[]> => {
	return useGetRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Categories], {
		params: {
			isChemical: true,
		},
	})
}

export const useCreatePartCategory = (): ApiPostMutation<PartCategoryPost, PartCategory> => {
	return usePostRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Categories], {
		keyToInvalidate: [PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Categories],
	})
}

export const useUpdatePartCategory = (): ApiPatchMutation<PartCategoryPatch> => {
	return usePatchRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Categories], {
		keyToInvalidate: [PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Categories],
	})
}

export const useDeletePartCategory = (): ApiDeleteMutation => {
	return useDeleteRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Categories], {
		keyToInvalidate: [PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Categories],
	})
}

export const usePartSystems = (): ApiGetQuery<CustomerSystem[]> => {
	return useGetRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Systems])
}

export const shippitApi = async (
	cart: {
		part: CustomerPart
		quantity: number
	}[],
	destination: {
		shipToSiteId?: string
		alternateAddress?: {
			address1?: string | null
			address2: string
			city: string
			state: string
			postalcode: string
		}
	}
): Promise<ShippitResponse> => {
	const { shipToSiteId, alternateAddress } = destination

	if (!shipToSiteId && !alternateAddress) {
		throw new Error("shippitApi called without shipToSiteId or alternateAddress")
	}

	const { data } = await apiClient.post<CamelToSnakeCaseNested<ShippitResponse>>(
		buildUrl("point_of_sale/order/summary/"),
		{
			ship_to_id: shipToSiteId,
			alternate_address: pythonify(alternateAddress),
			items: pythonify(
				cart.map(({ part, quantity }) => ({
					id: part.id,
					quantity,
				}))
			),
		}
	)

	return unpythonify(data)
}

export const useProductCatalogs = (
	options?: ApiGetQueryOptions<ProductCatalog[]>
): ApiGetQuery<ProductCatalog[]> => {
	return useGetRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Catalog], options)
}

export const useProductCatalog = (
	catalogId: string | null | undefined
): [ProductCatalog | undefined, boolean] => {
	const [catalogs, isLoading] = useProductCatalogs()

	const catalog = useMemo(() => {
		return (catalogs ?? []).find((c) => c.id.toString() === catalogId)
	}, [catalogId, catalogs])

	return [catalog, isLoading]
}

export const useCreateProductCatalog = (): ApiPostMutation<
	ProductCatalogPost,
	{ catalogId: string }
> => {
	return usePostRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Catalog], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useUpdateProductCatalog = (): ApiPatchMutation<ProductCatalogPatch> => {
	return usePatchRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Catalog], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useProductCatalogDetails = (
	id: string | null | undefined
): ApiGetQuery<ProductCatalogDetails> => {
	return useGetRequest(
		[
			PointOfSaleUrlPaths.PointOfSale,
			PointOfSaleUrlPaths.Details,
			PointOfSaleUrlPaths.Catalog,
			id ?? "",
		],
		{
			queryConfig: {
				enabled: !!id,
			},
		}
	)
}

export const useAddUserToCatalog = (): ApiPostMutation<ProductCatalogUserPOst> => {
	return usePostRequest(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.User, PointOfSaleUrlPaths.Catalog],
		{
			keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
		}
	)
}

export const useRemoveUserFromCatalog = (): ApiDeleteMutation<ProductCatalogUserDelete> => {
	return useDeleteRequest(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.User, PointOfSaleUrlPaths.Catalog],
		{
			keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
		}
	)
}

export const useAddPartToCatalog = (): ApiPostMutation<ProductCatalogPartPost> => {
	return usePostRequest(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Part, PointOfSaleUrlPaths.Catalog],
		{
			keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
		}
	)
}

export const useRemovePartFromCatalog = (): ApiDeleteMutation<ProductCatalogPartDelete> => {
	return useDeleteRequest(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.Part, PointOfSaleUrlPaths.Catalog],
		{
			keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
		}
	)
}

export const useBrandCards = (
	options?: ApiGetQueryOptions<BrandCard[], BrandCardQueryParams>
): ApiGetQuery<BrandCard[]> => {
	return useGetRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.BrandCard], options)
}

export const useBrandCard = (id: string | null | undefined): ApiGetQuery<BrandCard> => {
	return useGetRequest<BrandCard, BrandCardQueryParams>(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.BrandCard],
		{
			params: {
				card: id,
			},
			queryConfig: {
				enabled: !!id,
			},
		}
	)
}

export const useCreateBrandCard = (): ApiPostMutation<BrandCardPost> => {
	return usePostRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.BrandCard], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useUpdateBrandCard = (): ApiPatchMutation<BrandCardPatch> => {
	return usePatchRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.BrandCard], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useUploadBrandCardImage = (): ApiPostMutation<FormData, { imageUrl: string }> => {
	return usePostRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.BrandCardImage], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useSetBrandCardOrder = (): ApiPostMutation<BrandCardOrderPost> => {
	return usePostRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.BrandCardOrder], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useSetUserBrandCards = (): ApiPostMutation<UserBrandCardsPost> => {
	return usePostRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.UserBrandCard], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useCarouselImages = (
	options?: ApiGetQueryOptions<CarouselImage[], CarouselImageQueryParams>
): ApiGetQuery<CarouselImage[]> => {
	return useGetRequest(
		[PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.CarouselImage],
		options
	)
}

export const useCarouselImage = (id?: string): ApiGetQuery<CarouselImage> => {
	if (id) {
		return useGetRequest<CarouselImage, CarouselImageQueryParams>([
			PointOfSaleUrlPaths.PointOfSale,
			PointOfSaleUrlPaths.CarouselImage,
			id,
		])
	}
	return [undefined, undefined] as any
}

export const useCreateCarouselImage = (): ApiPostMutation<CarouselImagePost> => {
	return usePostRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.CarouselImage], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useUpdateCarouselImage = (): ApiPatchMutation<CarouselImagePatch> => {
	return usePatchRequest([PointOfSaleUrlPaths.PointOfSale, PointOfSaleUrlPaths.CarouselImage], {
		keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
	})
}

export const useUploadCarouselImage = (): ApiPostMutation<FormData, { imageUrl: string }> => {
	return usePostRequest(
		[
			PointOfSaleUrlPaths.PointOfSale,
			PointOfSaleUrlPaths.CarouselImage,
			PointOfSaleUrlPaths.CarouselImageUpload,
		],
		{
			keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
		}
	)
}

export const useSetCarouselImageOrder = (): ApiPostMutation<CarouselImageOrderPost> => {
	return usePostRequest(
		[
			PointOfSaleUrlPaths.PointOfSale,
			PointOfSaleUrlPaths.CarouselImage,
			PointOfSaleUrlPaths.CarouselImageOrder,
		],
		{
			keyToInvalidate: PointOfSaleUrlPaths.PointOfSale,
		}
	)
}

export const useCustomerIssueReasons = (): ApiGetQuery<IssueReason[]> => {
	return useGetRequest([
		PointOfSaleUrlPaths.PointOfSale,
		PointOfSaleUrlPaths.Issue,
		PointOfSaleUrlPaths.Reason,
	])
}

export const useCreateCustomerIssue = (): ApiPostMutation<CreateCustomerIssuePost> => {
	return usePostRequest([
		PointOfSaleUrlPaths.PointOfSale,
		PointOfSaleUrlPaths.Issue,
		PointOfSaleUrlPaths.Submission,
	])
}
