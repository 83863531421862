import React from "react"
export interface NyrisCameraIconProps {
	color?: string
	width?: string
	height?: string
}

export const NyrisCameraIcon: React.FC<NyrisCameraIconProps> = ({
	color,
	width,
	height,
	...rest
}) => {
	return (
		<span {...rest}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width ?? "23"}
				height={height ?? "22"}
				viewBox="0 0 23 22"
				fill="none"
			>
				<path
					d="M4.31974 0.75L3.24957 3.96051L0.0390625 5.03067V6.00133L3.24957 7.0715L4.31974 10.282H5.29039L6.36056 7.0715L9.57107 6.00133V5.03067L6.36056 3.96051L5.29039 0.75H4.31974Z"
					fill="url(#paint0_linear_2179_4469)"
				/>
				<path
					d="M12.4787 11.1434C11.3486 11.1434 10.4324 12.0596 10.4324 13.1897C10.4324 14.3199 11.3486 15.236 12.4787 15.236C13.6089 15.236 14.5251 14.3199 14.5251 13.1897C14.5251 12.0596 13.6089 11.1434 12.4787 11.1434Z"
					fill="url(#paint1_linear_2179_4469)"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M2.24713 12.1666V15.4829C2.24712 16.3065 2.24711 16.9863 2.29235 17.54C2.33934 18.1151 2.44018 18.6438 2.6932 19.1404C3.08558 19.9105 3.71167 20.5366 4.48175 20.9289C4.97833 21.1819 5.507 21.2828 6.08212 21.3298C6.63584 21.375 7.31558 21.375 8.13919 21.375H16.8183C17.6419 21.375 18.3216 21.375 18.8754 21.3298C19.4505 21.2828 19.9792 21.1819 20.4757 20.9289C21.2458 20.5366 21.8719 19.9105 22.2643 19.1404C22.5173 18.6438 22.6182 18.1151 22.6651 17.54C22.7104 16.9863 22.7104 16.3065 22.7104 15.4829L22.7104 10.1672C22.7111 9.1798 22.7116 8.44547 22.5058 7.81933C22.0998 6.58392 21.1309 5.61497 19.8954 5.20898C19.2693 5.00321 18.535 5.00369 17.5476 5.00434L17.5014 5.00437L16.6125 4.13094C16.3193 3.8424 16.0609 3.588 15.7506 3.40053C15.4781 3.23582 15.1816 3.11453 14.8717 3.04101C14.519 2.95733 14.1564 2.95766 13.745 2.95804L11.4555 2.95809L11.4556 7.05074L7.8745 8.58549L6.33976 12.1666H2.24713ZM8.3861 13.1897C8.3861 10.9294 10.2184 9.09707 12.4787 9.09707C14.7391 9.09707 16.5714 10.9294 16.5714 13.1897C16.5714 15.45 14.7391 17.2824 12.4787 17.2824C10.2184 17.2824 8.3861 15.45 8.3861 13.1897Z"
					fill="url(#paint2_linear_2179_4469)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_2179_4469"
						x1="1.92835"
						y1="2.3125"
						x2="22.5651"
						y2="21.5312"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#0B75E1" />
						<stop offset="1" stop-color="#87CEFA" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_2179_4469"
						x1="1.92835"
						y1="2.3125"
						x2="22.5651"
						y2="21.5312"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#0B75E1" />
						<stop offset="1" stop-color="#87CEFA" />
					</linearGradient>
					<linearGradient
						id="paint2_linear_2179_4469"
						x1="1.92835"
						y1="2.3125"
						x2="22.5651"
						y2="21.5312"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#0B75E1" />
						<stop offset="1" stop-color="#87CEFA" />
					</linearGradient>
				</defs>
			</svg>
		</span>
	)
}
