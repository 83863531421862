import React from "react"
import { css, Theme } from "@emotion/react"

export const ContentWrapperEcomm: React.FC = React.memo(({ children }) => {
	return <div css={contentWrapperStyle}>{children}</div>
})

const contentWrapperStyle = (theme: Theme) => css`
	max-width: 1900px;

	${theme.breakpoints.only("xs")} {
		padding-left: 16px;
		padding-right: 16px;
		padding: 0 1.5rem;
	}

	${theme.breakpoints.only("sm")} {
		padding-left: 24px;
		padding-right: 24px;
	}

	${theme.breakpoints.only("md")} {
		padding-left: 32px;
		padding-right: 32px;
	}

	${theme.breakpoints.up("lg")} {
		padding-left: 40px;
		padding-right: 40px;
		max-width: 2000px;
	}

	${theme.breakpoints.only("xl")} {
		margin: 0 auto;
	}
`
