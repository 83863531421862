import { useMemo } from "react"

import { useCustomerParts, isNonParentCustomerPart, NonParentCustomerPart } from "@ncs/ncs-api"
import { useChangeCallback } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

export const useUpdatePartTotals = (): void => {
	const [{ cart }, shopDispatch] = useShopContext()
	const onlinePartsIds = cart.map(({ part }) => part.id)
	const onlineParts = cart.map(({ part }) => part)

	// Feed the cart parts into the parts endpoint so we're getting back the very latest
	// price data, just in case we're coming back to this page after some time has gone
	// by and data could have gone stale.
	const { data: customerParts } = useCustomerParts({
		params: {
			onlinePartId: onlinePartsIds,
		},
		queryConfig: {
			enabled: cart.length > 0,
		},
		pageSize: 999, // In case there are lots of items in the cart.
	})

	// The online parts endpoint has the side effect of turning our NonParentCustomerParts into CustomerParts.
	// Flip 'em back.
	const partsData = useMemo(() => {
		const parts: NonParentCustomerPart[] = []

		customerParts.forEach((onlinePart) => {
			if (!isNonParentCustomerPart(onlinePart)) {
				throw new Error(
					"There was a parent part in the cart. This should not have happened"
				)
			} else {
				parts.push(onlinePart)
			}
		})

		return parts
	}, [customerParts])

	// When we get back updated parts data from the server, put that into the cart.
	useChangeCallback(partsData, (updatedPartsData) => {
		// If a user had added products to their cart anonymously, and they are now logging in to checkout
		// We need to make sure that they do not have any parts that are not included in their catalogue
		const updatedPartsNumbers = updatedPartsData.map(({ part }) => part.partNumber)
		const partsToRemove = onlineParts.filter(({ part }) => {
			if (updatedPartsNumbers.length > 0) {
				return !updatedPartsNumbers.includes(part.partNumber || "")
			} else {
				return false
			}
		})

		// If they do, we take em out.
		partsToRemove.forEach((part) => {
			shopDispatch({
				type: "remove from cart",
				payload: part.id,
			})
		})

		// Put the updated parts into the cart.
		shopDispatch({
			type: "update cart parts part data",
			payload: {
				parts: updatedPartsData,
			},
		})
	})
}
