import React, { useCallback, useMemo, useState, useEffect } from "react"
import { css, Theme, useTheme } from "@emotion/react"
import { captureException, captureMessage } from "@sentry/minimal"
import { useHistory } from "react-router-dom"

import {
	CustomerPartOrderPost,
	CustomerPartOrderPostSchema,
	isAxiosError,
	makeApiErrorMessage,
	PaymentMethod,
	PaymentMethodBundle,
	SquareCardBrand,
	useAuth,
	useCreateCustomerPartOrder,
	useCreatePunchoutOrder,
	useIsUser,
	UserId,
	useSite,
	useSites,
	useUserProfile,
} from "@ncs/ncs-api"
import { isCanadianProvince, noFalsy, unpythonify, validatePhone } from "@ncs/ts-utils"
import {
	Box,
	Card,
	encodeUrlState,
	Heading,
	ChevronDownIcon,
	ChevronUpIcon,
	Paragraph,
	useSquareCardContext,
} from "@ncs/web-legos"

import { useOrderTotals, useShopContext } from "~/contexts"
import { PageContentWrapper } from "~/shared-components"
import { ShopUrlState } from "~/views/shop/shop/Shop"

import { PunchOrderFinishedUrlState } from "../punchout-order-finished/PunchoutOrderFinished"
import { makeNewOrderLineItems } from "./checkout-util"
import {
	OrderSummaryEcomm,
	ChoosePaymentMethodEcomm,
	ChooseShippingAddressEcomm,
	ChooseShippingMethodsEcomm,
} from "./components"

import { useUpdatePartTotals } from "../../../util"

export const CheckoutEcomm: React.FC = () => {
	const isDb = useIsUser(UserId.DrivenBrands)
	const auth = useAuth()
	const history = useHistory()

	const [sites] = useSites()
	const hasAddress = sites ?? null
	const theme = useTheme()

	useUpdatePartTotals()

	const [{ cart, checkout }, shopDispatch] = useShopContext()

	const {
		totalItemCount,
		shipmentPartsSubtotal,
		shippingTotalWithoutAccessorials,
		shippingTotal,
		orderGrandTotal,
		totalTax,
	} = useOrderTotals()

	useEffect(() => {
		const phone = sites?.map((s) => s.phone).find((n) => n)
		if (phone) {
			shopDispatch({
				type: "set checkout phone",
				payload: phone,
			})
		}
	}, [sites, shopDispatch])

	const { card, verifyBuyer } = useSquareCardContext()

	const [stepOneOpen, setStepOneOpen] = useState(true)
	const [stepTwoOpen, setStepTwoOpen] = useState(true)
	const [stepThreeOpen, setStepThreeOpen] = useState(true)

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [hasShippingMethod, setHasShippingMethod] = useState(false)

	const createOrder = useCreateCustomerPartOrder()
	const createPunchoutOrder = useCreatePunchoutOrder()

	const [profile] = useUserProfile(auth.user?.id)
	const [shipToSite] = useSite(checkout.shipToSiteId)

	const isPunchout = !!auth.punchOutSessionId

	const isSectionAvailable = !!(
		(checkout?.shipToSiteId || checkout?.alternateAddress) &&
		(isDb || validatePhone(checkout?.phone))
	)

	const partOrderPostData = useMemo(() => {
		if (!checkout.shipToSiteId && !checkout.alternateAddress) {
			return null
		}
		if (!isDb && !checkout.phone) {
			return null
		}

		const order: CustomerPartOrderPost = {
			// If you're using an alternate address, then we only need the ship to if you're billing to account.
			shipToCustomerId:
				checkout.alternateAddress ?
					checkout.selectedPaymentMethod === PaymentMethod.CUSTOMER_ACCOUNT ?
						checkout.shipToSiteId ?? undefined
					:	undefined
				:	checkout.shipToSiteId ?? undefined,
			purchaseOrderNumber: checkout.purchaseOrder,
			comment: checkout.comment,
			lineItems: makeNewOrderLineItems(
				cart,
				checkout.shipments,
				checkout.accessorials,
				shippingTotalWithoutAccessorials
			),
			sessionId: auth.punchOutSessionId,
			alternateAddress:
				checkout.alternateAddress ?
					{
						address1: checkout.alternateAddress.address1 ?? null,
						address2: checkout.alternateAddress.address2,
						city: checkout.alternateAddress.city,
						state: checkout.alternateAddress.state,
						postalcode: checkout.alternateAddress.zip,
					}
				:	undefined,
			contactPhone: checkout.phone ?? undefined,
		}

		return order
	}, [
		isDb,
		cart,
		checkout.purchaseOrder,
		checkout.accessorials,
		checkout.comment,
		checkout.shipToSiteId,
		checkout.shipments,
		shippingTotalWithoutAccessorials,
		auth.punchOutSessionId,
		checkout.alternateAddress,
		checkout.selectedPaymentMethod,
		checkout.phone,
	])

	const beginOrderPlacement = async () => {
		if (!partOrderPostData) {
			console.error("Trying to place order but order data was not complete")
			return
		}

		// Turn on isSubmitting here. Each method is responsible for turning it off later.
		setIsSubmitting(true)

		if (isPunchout) {
			void postPunchoutOrder(partOrderPostData)
			return
		}

		switch (checkout.selectedPaymentMethod) {
			case PaymentMethod.SQUARE_CARD: {
				if (!card) {
					throw new Error("Square card instance not found.")
				}

				const tokenResult = await card.tokenize()
				if (tokenResult.status === "OK") {
					if (!tokenResult.token) throw new Error("Token not found on token result")

					// Now that we've got the nonce, we need to call a separate Square endpoint
					// to verify buyer details and charge amount.
					if (!profile) throw new Error("User profile not loaded")

					const verificationResult = await verifyBuyer(tokenResult.token, {
						amount: String(orderGrandTotal),
						currencyCode: "USD",
						intent: "CHARGE",
						billingContact: {
							familyName: profile.lastName,
							givenName: profile.firstName,
							email: profile.email,
							countryCode: "US",
							city: shipToSite?.city ?? checkout.alternateAddress?.city,
							addressLines: noFalsy([
								shipToSite?.address1 ?? checkout.alternateAddress?.address1,
								shipToSite?.address2 ?? checkout.alternateAddress?.address2,
							]),
							state: shipToSite?.state ?? checkout.alternateAddress?.state,
							postalCode: shipToSite?.postalCode ?? checkout.alternateAddress?.zip,
						},
					})
					if (!verificationResult) throw new Error("Error verifying card.")

					// Now put it all together and send to server.
					const { details } = tokenResult
					if (!details?.card) throw new Error("Card details not found in token result.")

					const payment: PaymentMethodBundle = {
						method: PaymentMethod.SQUARE_CARD,
						data: {
							last4: details.card.last4,
							expMonth: details.card.expMonth,
							expYear: details.card.expYear,
							brand: details.card.brand as unknown as SquareCardBrand,
							nonce: tokenResult.token,
							verificationToken: verificationResult.token,
							billingPostalCode: tokenResult.details?.billing?.postalCode ?? null,
							digitalWalletType: "NONE", // If we ever support Google Pay or Apple Pay, this'll need to change...
						},
					}

					void postNewOrder({
						...partOrderPostData,
						payment,
					})
				} else {
					console.error("Error tokenizing")
					setIsSubmitting(false)
				}
				break
			}
			case PaymentMethod.STORED_BANK_ACCOUNT: {
				if (!checkout.selectedBankAccount?.id) {
					throw new Error(
						"Trying to check out with stored bank account, but there isn't one selected in checkout state."
					)
				}

				void postNewOrder({
					...partOrderPostData,
					payment: {
						method: PaymentMethod.STORED_BANK_ACCOUNT,
						data: checkout.selectedBankAccount.id,
					},
				})
				break
			}
			case PaymentMethod.STORED_CARD: {
				if (!checkout.selectedCard?.id) {
					throw new Error(
						"Trying to check out with stored card but there isn't one selected in checkout state."
					)
				}

				void postNewOrder({
					...partOrderPostData,
					payment: {
						method: PaymentMethod.STORED_CARD,
						data: checkout.selectedCard.id,
					},
				})
				break
			}
			case PaymentMethod.CUSTOMER_ACCOUNT: {
				void postNewOrder(partOrderPostData)
				break
			}
			default: {
				console.error("No handler for selected payment method")
			}
		}
	}

	const postNewOrder = async (orderData: CustomerPartOrderPost) => {
		try {
			setIsSubmitting(true)
			setErrorMessage(null)
			const parseResult = CustomerPartOrderPostSchema.safeParse(orderData)
			if (!parseResult.success) {
				console.warn("Order data did not pass parse check", orderData)
				captureMessage("Order data post failed parsing! Posting anyway...", {
					extra: { orderData },
				})
			}

			const response = await createOrder(orderData)
			shopDispatch({
				type: "reset checkout state",
			})
			const newOrder = unpythonify(response.data)
			history.replace({
				pathname: "/shop",
				search: encodeUrlState<ShopUrlState>({
					fromOrder: newOrder.orderId.toString(),
				}),
			})
		} catch (e) {
			if (isAxiosError(e)) {
				const paymentMessage = (e.response?.data as { payment?: string })?.payment
				if (paymentMessage) {
					setErrorMessage(paymentMessage)
				}
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const postPunchoutOrder = async (orderData: CustomerPartOrderPost) => {
		try {
			setIsSubmitting(true)
			setErrorMessage(null)
			const parseResult = CustomerPartOrderPostSchema.safeParse(orderData)
			if (!parseResult.success) {
				console.warn("Punchout order data did not pass parse check", orderData)
				captureMessage("Punchout order data post failed parsing! Posting anyway...", {
					extra: { orderData },
				})
			}
			const response = await createPunchoutOrder(orderData)
			const redirectUrl = response.data
			// (The punchout order placed page will clear out shop context local storage and logout)
			history.replace({
				pathname: "/shop/punchout-order-finished",
				search: encodeUrlState<PunchOrderFinishedUrlState>({
					redirect: redirectUrl,
				}),
			})
		} catch (e) {
			setErrorMessage(makeApiErrorMessage(e))
			captureException(e)
			setIsSubmitting(false)
		}
	}

	const updateComment = useCallback(
		(newValue: string | null) => {
			shopDispatch({
				type: "set checkout comment",
				payload: newValue || null,
			})
		},
		[shopDispatch]
	)

	const isCanada = useMemo(() => {
		return isCanadianProvince(checkout.alternateAddress?.state)
	}, [checkout.alternateAddress?.state])

	return (
		<PageContentWrapper
			title="Checkout"
			breadcrumbs={[{ name: "Shop", to: "/shop" }, { name: "Checkout" }]}
		>
			<Paragraph fontSize={1.5} mt={1.5} textAlign="left" bold>
				Checkout
			</Paragraph>

			<div css={outerContainerStyle}>
				<div>
					<Card css={stepContainerStyle(theme, !!hasAddress)}>
						<Box
							d="flex"
							css={css`
								width: 100%;
							`}
						>
							<div css={styleStepNumber(theme, true)}>
								<div>1</div>
							</div>
							<Box
								css={css`
									width: 100%;
								`}
							>
								<Heading
									bold
									mb={1}
									css={css`
										color: #0b75e1;
									`}
								>
									<Box d="flex">
										<div css={styleStepNumberText(theme, true)}>
											Shipping Address
										</div>
										<button
											css={styleCollapseIcon}
											onClick={() => setStepOneOpen((state) => !state)}
										>
											{stepOneOpen ?
												<ChevronUpIcon color="black" width={16} />
											:	<ChevronDownIcon color="black" width={16} />}
										</button>
									</Box>
								</Heading>
								{stepOneOpen && (
									<div className="step-content">
										<ChooseShippingAddressEcomm
											updateComment={updateComment}
										/>
									</div>
								)}
							</Box>
						</Box>
					</Card>

					<Card css={stepContainerStyle(theme, !!hasAddress)}>
						<Box
							d="flex"
							css={css`
								width: 100%;
							`}
						>
							<div css={styleStepNumber(theme, isSectionAvailable)}>
								<div>2</div>
							</div>
							<Box
								css={css`
									width: 100%;
								`}
							>
								<Heading
									bold
									mb={1}
									css={css`
										color: #0b75e1;
									`}
								>
									<Box d="flex">
										<div css={styleStepNumberText(theme, isSectionAvailable)}>
											Shipping Method
										</div>
										{isSectionAvailable ?
											<button
												css={styleCollapseIcon}
												onClick={() => setStepTwoOpen((state) => !state)}
											>
												{stepTwoOpen ?
													<ChevronUpIcon color="black" width={16} />
												:	<ChevronDownIcon color="black" width={16} />}
											</button>
										:	<button css={styleCollapseIcon}>
												<ChevronUpIcon width={16} color="#9CA3AF" />
											</button>
										}
									</Box>
								</Heading>
								{isSectionAvailable && stepTwoOpen && (
									<div className="step-content">
										<ChooseShippingMethodsEcomm
											setHasShippingMethod={setHasShippingMethod}
										/>
									</div>
								)}
							</Box>
						</Box>
					</Card>

					<Card css={stepContainerStyle(theme, !!hasAddress && hasShippingMethod)}>
						<Box
							d="flex"
							css={css`
								width: 100%;
							`}
						>
							<div css={styleStepNumber(theme, isSectionAvailable)}>
								<div>3</div>
							</div>
							<Box
								css={css`
									width: 100%;
								`}
							>
								<Heading
									bold
									mb={1}
									css={css`
										color: #0b75e1;
									`}
								>
									<Box d="flex">
										<div css={styleStepNumberText(theme, isSectionAvailable)}>
											Payment Method
										</div>
										{isSectionAvailable ?
											<button
												css={styleCollapseIcon}
												onClick={() => setStepThreeOpen((state) => !state)}
											>
												{stepThreeOpen ?
													<ChevronUpIcon color="black" width={16} />
												:	<ChevronDownIcon color="black" width={16} />}
											</button>
										:	<button css={styleCollapseIcon}>
												<ChevronUpIcon color="#9CA3AF" />
											</button>
										}
									</Box>
								</Heading>
								{isSectionAvailable && stepThreeOpen && (
									<div className="step-content">
										{!isPunchout ?
											<ChoosePaymentMethodEcomm />
										:	<Paragraph color="secondary">
												Payment will be handled by the client you initiated
												this session from
											</Paragraph>
										}
									</div>
								)}
							</Box>
						</Box>
					</Card>
				</div>

				<div
					css={css`
						margin-top: 2em;
					`}
				>
					<OrderSummaryEcomm
						profile={profile}
						isDb={isDb}
						errorMessage={errorMessage}
						shipToSite={shipToSite}
						setErrorMessage={setErrorMessage}
						isCanada={isCanada}
						onPlaceOrder={beginOrderPlacement}
						isSubmitting={isSubmitting}
						orderGrandTotal={orderGrandTotal}
						totalItemCount={totalItemCount}
						shipmentPartsSubtotal={shipmentPartsSubtotal}
						shippingTotal={shippingTotal}
						totalTax={totalTax}
					/>
				</div>
			</div>
		</PageContentWrapper>
	)
}

const outerContainerStyle = (theme: Theme) => css`
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 2rem;
	${theme.breakpoints.down("sm")} {
		grid-template-columns: 1fr;
	}
`
const stepContainerStyle = (theme: Theme, hasAddress?: boolean) => css`
	color: ${hasAddress ? "black" : theme.palette.text.secondary};
	margin-top: 2rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #eee;
`

const styleStepNumberText = (theme: Theme, active?: boolean) => css`
	color: ${active ? "#0b75e1" : "#9CA3AF"};
`

const styleStepNumber = (theme: Theme, active?: boolean) => css`
	margin-top: -0.2em;
	width: 32px;
	height: 30px;
	border: 1px solid ${active ? "#0b75e1" : "#9CA3AF"};
	border-radius: 1em;
	text-align: center;
	margin-right: 1em;
	padding-top: 5px;

	color: ${active ? "#0b75e1" : "#9CA3AF"};
	text-align: center;
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	div {
		margin-top: -2px;
	}
`

const styleCollapseIcon = css`
	all: unset;
	right: 0.5em;
	position: absolute;
	cursor: pointer;
	height: 1em;
	width: 1em;
`
