import { ReactElement } from "react"
import { css } from "@emotion/react"
import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { PhoneInputEcomm, PhoneInputEcommProps } from "../inputs"

interface PhoneInputFormFieldProps<TFieldValues extends FieldValues> extends PhoneInputEcommProps {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
	placeholder?: string
	emptyValueFallback?: string | null
}

export const PhoneInputFormFieldEcomm = <TFieldValues extends FieldValues>({
	name,
	control,
	placeholder,
	emptyValueFallback = null,
	...rest
}: PhoneInputFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<PhoneInputEcomm
						placeholder={placeholder}
						{...rest}
						onBlur={onBlur}
						value={value != null ? String(value).trim() : emptyValueFallback}
						onChange={(newValue) => onChange(newValue || emptyValueFallback)}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}
