import { useState, useEffect, useMemo } from "react"
import { CartItem, useTaxEstimate, TaxPricingPost, CustomerSite } from "../portal-apps"

const useGetTaxes = (
	cart?: CartItem[],
	customerId?: string | undefined,
	calculateTax?: boolean,
	isGuest?: boolean,
	alternateAddress?: CustomerSite
) => {
	const [totalTax, setTotalTax] = useState<number>(0)
	const [error, setError] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const fetchTaxEstimate = useTaxEstimate()

	const lineItems = useMemo(
		() =>
			cart?.map((item) => ({
				part_number: item?.part.part.partNumber,
				amount: item?.part?.netPrice,
				quantity: item?.quantity,
			})),
		[cart]
	)

	const shipTo = {
		line1: alternateAddress?.address1 ?? "",
		line2: alternateAddress?.address2 ?? "",
		city: alternateAddress?.city ?? "",
		region: alternateAddress?.state ?? "",
		postalCode: alternateAddress?.zip ?? "",
	}

	useEffect(() => {
		setIsLoading(true)
		const setPayload = () => {
			if (isGuest && alternateAddress) {
				return {
					shipTo: shipTo,
					ecommGuest: isGuest,
					lines: lineItems ?? [],
				} as TaxPricingPost
			} else {
				return {
					customerId: customerId ?? null,
					lines: lineItems ?? [],
				} as TaxPricingPost
			}
		}

		const getTaxEstimate = async () => {
			if (
				!lineItems ||
				lineItems.length === 0 ||
				!calculateTax ||
				(!isGuest && !customerId)
			) {
				setTotalTax(totalTax)
				setIsLoading(false)
				return
			}

			try {
				const response = await fetchTaxEstimate(setPayload())
				setTotalTax(response?.data?.total_tax ?? 0)
			} catch (err) {
				setIsLoading(false)
				console.error(err)
				setError("Error fetching tax estimate")
			} finally {
				setIsLoading(false)
			}
		}

		getTaxEstimate()
	}, [fetchTaxEstimate, lineItems, customerId, calculateTax, alternateAddress, isGuest])

	return { totalTax, error, isLoading }
}

export default useGetTaxes
