import React, { FC } from "react"
import { css, Theme } from "@emotion/react"
import { CustomerCarouselImage, useCustomerCarouselImages } from "@ncs/ncs-api"
import {
	useScreenSizeMatch,
	Box,
	Carousel,
	ChevronLeftIcon,
	ChevronRightIcon,
	handleNyrisSearch,
} from "@ncs/web-legos"
import { useHistory } from "react-router-dom"

const CustomPrevArrow = (onClickHandler: () => void, hasPrev: boolean) => {
	return (
		hasPrev && (
			<button css={prevArrowStyle} onClick={onClickHandler}>
				<ChevronLeftIcon />
			</button>
		)
	)
}

const CustomNextArrow = (onClickHandler: () => void, hasNext: boolean) => {
	return (
		hasNext && (
			<button css={nextArrowStyle} onClick={onClickHandler}>
				<ChevronRightIcon />
			</button>
		)
	)
}

export const ShopCarousel: FC = () => {
	const history = useHistory()
	const screenIsTiny = useScreenSizeMatch("xs")
	const screenIsSmall = useScreenSizeMatch("sm")
	const screenIsMd = useScreenSizeMatch("md")
	const screenIsLg = useScreenSizeMatch("lg")
	const screenIsXl = useScreenSizeMatch("xl")

	const [images, imagesLoading] = useCustomerCarouselImages()
	const handleClickItem = (index: number) => {
		const destinationUrl = images ? images[index].destinationUrl : undefined
		if (destinationUrl) {
			if (destinationUrl === "nyris") {
				handleNyrisSearch()
			} else if (destinationUrl.startsWith(window.location.origin)) {
				history.push(destinationUrl.replace(window.location.origin, ""))
			} else {
				window.open(destinationUrl, "_blank")
			}
		}
	}

	return (
		<Box css={shopCarouselStyle}>
			{!imagesLoading && images && images?.length > 0 && (
				<Carousel
					interval={7000}
					autoPlay
					infiniteLoop
					emulateTouch={screenIsSmall}
					swipeable={screenIsSmall}
					swipeScrollTolerance={screenIsSmall ? 100 : undefined}
					preventMovementUntilSwipeScrollTolerance={screenIsSmall}
					onClickItem={handleClickItem}
					height={screenIsSmall ? "164px" : "332px"}
					stopOnHover={false}
					showStatus={false}
					showIndicators={true}
					showThumbs={false}
					renderArrowPrev={CustomPrevArrow}
					renderArrowNext={CustomNextArrow}
				>
					{images.map((image) => (
						<div key={image.id} css={image.destinationUrl && pointerStyle}>
							<img
								height={screenIsSmall ? "164px" : "332px"}
								css={imageStyle}
								src={getCarouselImageUrl(
									image,
									screenIsTiny,
									screenIsSmall,
									screenIsMd,
									screenIsLg,
									screenIsXl
								)}
								alt={image.name}
							/>
							{image.text && <p className="legend">{image.text}</p>}
						</div>
					))}
				</Carousel>
			)}
		</Box>
	)
}

const imageStyle = (theme: Theme) => css`
	height: auto; // Adjust as necessary
	width: auto;
	vertical-align: top;
	border: 0;
	min-width: 100%;
	max-height: 332px;
	height: 332px;
	object-fit: cover;
	object-position: center;
	border-radius: 8px;

	${theme.breakpoints.down("sm")} {
		height: 164px;
	}
`

const pointerStyle = css`
	cursor: pointer;
`

const nextArrowStyle = css`
	padding-top: 6px;
	background: #111827;
	height: 40px;
	width: 40px;
	border-radius: 10em;
	right: 24px;
	z-index: 1;
	position: absolute;
	top: 45%;
	border: none;
	vertical-align: middle;
	text-align: center;
	opacity: 0.3;

	&:hover {
		opacity: 0.8;
		border: 2px solid white;
	}
`

const prevArrowStyle = css`
	padding-top: 6px;
	text-align: center;
	vertical-align: middle;
	border: none;
	background: #111827;
	height: 40px;
	width: 40px;
	border-radius: 10em;
	left: 24px;
	z-index: 1;
	position: absolute;
	top: 45%;
	opacity: 0.3;

	&:hover {
		opacity: 0.8;
		border: 2px solid white;
	}
`

const shopCarouselStyle = (theme: Theme) => css`
	margin-top: 0px;
`

const getCarouselImageUrl = (
	image: CustomerCarouselImage,
	screenIsTiny: boolean,
	screenIsSmall: boolean,
	screenIsMd: boolean,
	screenIsLg: boolean,
	screenIsXl: boolean
): string => {
	if (image.mobileImageUrl && screenIsTiny) {
		return image.mobileImageUrl
	} else if (image.tabletImageUrl && screenIsSmall) {
		return image.tabletImageUrl
	} else if (image.desktopImageUrl && screenIsMd) {
		return image.desktopImageUrl
	} else if (image.tvImageUrl && (screenIsLg || screenIsXl)) {
		return image.tvImageUrl
	}

	return image.desktopImageUrl
}
