import React from "react"
export interface LogoutIconProps {
	color?: string
}

export const LogoutIcon: React.FC<LogoutIconProps> = ({ color, ...rest }) => {
	return (
		<span {...rest}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="14"
				viewBox="0 0 16 14"
				fill="none"
			>
				<path
					d="M15.7812 7.53125C16.075 7.2375 16.075 6.7625 15.7812 6.47188L11.7812 2.46875C11.4875 2.175 11.0125 2.175 10.7219 2.46875C10.4313 2.7625 10.4281 3.2375 10.7219 3.52812L13.4406 6.24687L5.75 6.25C5.33437 6.25 5 6.58437 5 7C5 7.41563 5.33437 7.75 5.75 7.75H13.4406L10.7219 10.4688C10.4281 10.7625 10.4281 11.2375 10.7219 11.5281C11.0156 11.8187 11.4906 11.8219 11.7812 11.5281L15.7812 7.53125ZM5.25 1.5C5.66563 1.5 6 1.16562 6 0.75C6 0.334375 5.66563 0 5.25 0H2.75C1.23125 0 0 1.23125 0 2.75V11.25C0 12.7688 1.23125 14 2.75 14H5.25C5.66563 14 6 13.6656 6 13.25C6 12.8344 5.66563 12.5 5.25 12.5H2.75C2.05938 12.5 1.5 11.9406 1.5 11.25V2.75C1.5 2.05938 2.05938 1.5 2.75 1.5H5.25Z"
					fill="black"
				/>
			</svg>
		</span>
	)
}
