import React from "react"
export interface ChevronLeftIconProps {
	color?: string
}

export const ChevronLeftIcon: React.FC<ChevronLeftIconProps> = ({ color, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M15.4715 18.4395C15.6829 18.6508 15.8016 18.9374 15.8016 19.2363C15.8016 19.5352 15.6829 19.8219 15.4715 20.0332C15.2602 20.2445 14.9735 20.3633 14.6746 20.3633C14.3758 20.3633 14.0891 20.2445 13.8778 20.0332L6.37776 12.5332C6.27288 12.4287 6.18967 12.3045 6.13288 12.1677C6.0761 12.031 6.04688 11.8844 6.04688 11.7363C6.04688 11.5883 6.0761 11.4417 6.13288 11.3049C6.18967 11.1682 6.27288 11.044 6.37776 10.9395L13.8778 3.43945C14.0891 3.22811 14.3758 3.10937 14.6746 3.10938C14.9735 3.10938 15.2602 3.22811 15.4715 3.43945C15.6829 3.6508 15.8016 3.93744 15.8016 4.23633C15.8016 4.53521 15.6829 4.82186 15.4715 5.0332L8.76932 11.7354L15.4715 18.4395Z"
				fill={color || "white"}
			/>
		</svg>
	)
}
