const units: Record<string, string> = {
	EA: "Each",
	GL: "Gallon",
	FT: "Feet",
	PK: "Pack",
	YD: "Yard",
	CS: "Case",
	IN: "Inches",
	RO: "Roll",
	CA: "Cases",
	PA: "Pallets",
}

export const UnitMapping = (unit: string): string => units[unit] ?? unit
