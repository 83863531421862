import React, { memo, useMemo, useState } from "react"

import { CustomerSite, useAuth, useSites } from "@ncs/ncs-api"
import {
	AddressFormModal,
	Box,
	Button,
	Callout,
	GenericAddress,
	getAddressFields,
	getGenericAddress,
	Modal,
	Paragraph,
	ParagraphList,
	SearchableSiteSelector,
	TrackingEvent,
	trackEvent,
	UspsAddress,
} from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

export interface SelectAddressModalEcommProps {
	isOpen: boolean
	onClose: () => void
	disableAlternateAddress?: boolean
}

export const SelectAddressModalEcomm: React.FC<SelectAddressModalEcommProps> = memo(
	({ isOpen, onClose, disableAlternateAddress = false }) => {
		const { user } = useAuth()
		const [, shopDispatch] = useShopContext()
		const [selectedSite, setSelectedSite] = useState<CustomerSite | null>(null)
		const [alternateAddress, setAlternateAddress] = useState<UspsAddress | null>(null)
		const [sites, sitesAreLoading] = useSites()
		const [showAlternateAddressForm, setShowAlternateAddressForm] = useState(false)

		const onSave = () => {
			if (selectedSite) {
				shopDispatch({
					type: "select shipping site",
					payload: {
						siteId: selectedSite.id,
					},
				})
			}

			if (alternateAddress) {
				shopDispatch({
					type: "select alternate address",
					payload: {
						alternateAddress: getGenericAddress(alternateAddress),
						preserveShipToId: true,
					},
				})
			}

			onClose()
			trackEvent(TrackingEvent.SHIP_HERE)
		}

		const onSiteSelect = (site: CustomerSite | null) => {
			setAlternateAddress(null)
			setSelectedSite(site)
		}

		const onAlternateAddressSave = (address: UspsAddress) => {
			setShowAlternateAddressForm(false)
			setSelectedSite(null)
			setAlternateAddress(address)
		}

		/** The final address, derived from either the selected sited or the alternate address. */
		const selectedDestination: GenericAddress | null = useMemo(() => {
			if (alternateAddress) {
				return getGenericAddress(alternateAddress)
			}
			if (selectedSite) {
				return getGenericAddress(selectedSite)
			}

			return null
		}, [alternateAddress, selectedSite])

		const reset = () => {
			setSelectedSite(null)
			setAlternateAddress(null)
			setShowAlternateAddressForm(false)
		}

		const hasSites = useMemo(() => {
			return !!sites && sites.length > 0
		}, [sites])

		return (
			<>
				<Modal
					title="Choose New Destination"
					isOpen={isOpen}
					onOpen={reset}
					onClose={onClose}
					rightButtons={{
						buttonText: "Ship Here",
						variant: "primary-cta",
						onClick: onSave,
						disabled: !selectedDestination,
					}}
				>
					<Paragraph mb={1}>
						{disableAlternateAddress ?
							"Choose which site to ship to:"
						:	"You can ship directly to one of your car wash sites if you have them set up with NCS, or to anywhere else by adding another address below."
						}
					</Paragraph>

					<Paragraph bold mb={0.5}>
						Ship to site
					</Paragraph>
					{!hasSites && !sitesAreLoading ?
						<Paragraph small color="secondary">
							No sites set up for this account yet
						</Paragraph>
					:	<SearchableSiteSelector
							value={selectedSite}
							onChange={onSiteSelect}
							fillContainer
							mb={0}
							label=""
						/>
					}

					{/* Show destination here when it's a site */}
					{!!selectedDestination && !alternateAddress && (
						<Callout icon="check" mt={1}>
							<ParagraphList lines={getAddressFields(selectedDestination)} />
						</Callout>
					)}

					{!disableAlternateAddress && (
						<>
							<Paragraph bold mb={0.5} mt={2}>
								Or ship to an alternate address
							</Paragraph>
							{!alternateAddress && (
								<Button
									icon="pencil"
									onClick={() => setShowAlternateAddressForm(true)}
								>
									Enter address
								</Button>
							)}
						</>
					)}

					{/* Or show destination here when it's an alternate address. */}
					{!!selectedDestination && !!alternateAddress && (
						<Box display="flex" columnGap={1} alignItems="center">
							<Callout icon="check" mt={0.5}>
								<ParagraphList lines={getAddressFields(selectedDestination)} />
							</Callout>
							<Button icon="trash" onClick={() => setAlternateAddress(null)}>
								Remove
							</Button>
						</Box>
					)}
				</Modal>

				<AddressFormModal
					isOpen={showAlternateAddressForm}
					onClose={() => setShowAlternateAddressForm(false)}
					onSave={onAlternateAddressSave}
					hardCodedFields={
						user?.name ?
							{
								name: user.name,
							}
						:	undefined
					}
					allowCanada={hasSites}
				/>
			</>
		)
	}
)
