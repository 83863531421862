import { FC, useMemo } from "react"
import { useCustomerBrandCards } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Divider,
	GridContainer,
	GridItem,
	GridItemProps,
	Heading,
	LoadingSpinner,
} from "@ncs/web-legos"
import { ShopBrandCtaEcomm } from "./ShopBrandCtaEcomm"

export const BrandCtaGridEcomm: FC = () => {
	const [cards, cardsLoading] = useCustomerBrandCards()

	const gridItemProps: GridItemProps = useMemo(() => {
		if (!cards || !cards.length) {
			return {}
		}
		if (cards.length === 1) {
			return {
				xs: 12,
			}
		}
		if (cards.length === 2) {
			return {
				xs: 12,
				sm: 6,
			}
		}
		return {
			xs: 12,
			sm: 6,
			md: 4,
		}
	}, [cards])

	if (cardsLoading) {
		return <LoadingSpinner />
	}

	return (
		<>
			<Heading fontSize={1.5} mb={1} mt={1.5} bold>
				Our Catalogs
			</Heading>
			<GridContainer
				rowGap={3}
				columnGap={3}
				justifyContent="center"
				smProps={{ columnGap: 2 }}
				xsProps={{ columnGap: 0 }}
			>
				{(cards ?? []).map((card, i) => {
					return (
						<GridItem key={card.id} {...gridItemProps}>
							<AnimatedEntrance show delay={75 * i}>
								<ShopBrandCtaEcomm card={card} />
							</AnimatedEntrance>
						</GridItem>
					)
				})}
			</GridContainer>

			<Divider my={3} />
		</>
	)
}
