import React, { useMemo } from "react"
import { css } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { Icon, trackEvent, TrackingEvent } from "@ncs/web-legos"
import { Button, Divider, Paragraph, PartImage } from "@ncs/web-legos"
import { useShopContext } from "~/contexts"
import { QuantityControlEcomm } from "~/shared-components/quantity-control-ecomm/QuantityControlEcomm"

export interface AddedToCartModalEcommProps {
	isOpen?: boolean
	onClose?: () => void
	partId?: string
}

export const AddedToCartModalEcomm: React.FC<AddedToCartModalEcommProps> = ({
	isOpen,
	onClose,
	partId,
}) => {
	const history = useHistory()
	const [{ cart }, shopDispatch] = useShopContext()
	const cartItems = cart?.length

	const cartPart = useMemo(() => {
		return cart.find((c) => c.part.id === partId)
	}, [partId, cart])

	if (!partId || !cartPart) {
		return null
	}

	const { part, quantity } = cartPart

	const onChangeQuantity = (newQuantity: number) => {
		shopDispatch({
			type: "update part cart quantity",
			payload: {
				partId,
				quantity: newQuantity,
			},
		})
	}

	const onRemove = () => {
		shopDispatch({
			type: "remove from cart",
			payload: partId,
		})
	}

	const goToCart = () => {
		onClose?.()
		history.push("/shop/cart-summary")
		trackEvent(TrackingEvent.GO_TO_CART)
	}

	return (
		<a
			css={overlay(isOpen)}
			href={"null"}
			onClick={(e) => {
				e.preventDefault()
				if (e.target === e.currentTarget) {
					onClose?.()
				}
			}}
		>
			<div css={cartModal}>
				<div css={productContainer}>
					<div css={header}>
						<Paragraph bold>ADDED TO CART</Paragraph>
						<Button onClick={onClose}>
							<Icon icon="close" fontSize={1.5} />
						</Button>
					</div>
					<Divider css={divider} />
					<div css={row}>
						<div>
							<div css={imageBorder}>
								<PartImage src={part.imageUrl} alt={part.title} css={image} />
							</div>
							<div>
								<Paragraph bold mb={0.5} mr={1}>
									{part.title}
								</Paragraph>
								<Paragraph customColor="#4B5563">
									#{part?.onlinePartNumber}
								</Paragraph>
							</div>
						</div>
						<QuantityControlEcomm
							value={quantity}
							onChange={onChangeQuantity}
							onChooseZero={onRemove}
							useUpdateButton
						/>
					</div>
				</div>
				<div css={button}>
					<Button customVariant="#0b75e1" onClick={goToCart}>
						VIEW CART ({cartItems})
					</Button>
				</div>
			</div>
		</a>
	)
}

const overlay = (isOpen: boolean | undefined) => css`
	right: 0;
	top: 0;
	position: absolute;
	left: 0;
	bottom: 0;
	display: ${isOpen ? "block" : "none"};
	z-index: 1;
	cursor: inherit;
`

const cartModal = css`
	width: 100%;
	max-width: 32rem;
	border-radius: 8px;
	border: 1px solid #e5e6e6;
	background: #ffffff;
	box-shadow: 0px 0px 4px 0px #d1d5db;
	padding: 1.5rem;
	position: fixed;
	right: 0.25rem;
	top: 0.25rem;
	z-index: 2;
	opacity: 1;
`

const header = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	button.MuiButtonBase-root path {
		width: 2rem;
		height: 2rem;
	}
`

const divider = css`
	margin-bottom: 1rem;
`

const image = css`
	width: 100%;
	height: 100%;
	border-radius: 0.2rem;
	overflow: hidden;
`

const productContainer = css`
	margin-bottom: 1.5rem;
`

const imageBorder = css`
	border-radius: 4px;
	border: 1px solid #e5e6e6;
	padding: 0.375rem;
	margin-right: 1rem;
	width: 100%;
	height: 4.5rem;
	max-width: 4.5rem;
	img {
		display: block;
	}
`

const row = css`
	display: flex;
	justify-content: space-between;
	align-items: space-between;
	> div:first-child {
		display: flex;
		margin-right: 1rem;
	}
`

const button = css`
	display: flex;
	justify-content: center;
	.button-text {
		font-size: 0.875rem;
	}
`
