import React from "react"
import { css } from "@emotion/react"
import { Paragraph } from "@ncs/web-legos"
import { PartSearchFiltersEcomm } from "./PartSearchFiltersEcomm"

export const PartSearchSidebarEcomm: React.FC = () => {
	return (
		<div css={containerStyle}>
			<Paragraph customColor="#111827" fontSize={1.25} bold>
				FILTERS
			</Paragraph>
			<PartSearchFiltersEcomm />
		</div>
	)
}

const containerStyle = css`
	margin-top: 4.5rem;
	width: 14rem;
	border-right: 1px solid #e5e7eb;
	padding-right: 3rem;
	height: 100%;
`
