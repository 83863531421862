import { useState } from "react"
import { NavLink } from "react-router-dom"
import { css } from "@emotion/react"
import { PartCategory, usePartCategories, usePartChemicals } from "@ncs/ncs-api/portal-apps"
import { ChevronDownIcon, ChevronUpIcon } from "@ncs/web-legos"

export const SiteNavigationEcomm: React.FC = () => {
	const [toggleCategories, setToggleCategories] = useState(false)
	const [toggleChemicals, setToggleChemicals] = useState(false)

	const [categories] = usePartCategories()
	const [chemicals] = usePartChemicals()

	return (
		<nav css={navContainer}>
			<ul>
				<li css={navElement}>
					<button onClick={() => setToggleCategories(!toggleCategories)}>
						<div css={category}>
							Parts
							{toggleCategories ?
								<ChevronUpIcon color="#00050A" />
							:	<ChevronDownIcon color="#00050A" />}
						</div>
					</button>
					{toggleCategories && (
						<ul>
							{(categories ?? []).map((category: PartCategory) => (
								<li key={category?.id} css={subCategories}>
									<NavLink
										to={`/shop/search?categories=${category?.id}&ordering=title`}
									>
										{category?.name}
									</NavLink>
								</li>
							))}
						</ul>
					)}
				</li>
				<li css={navElement}>
					<button onClick={() => setToggleChemicals(!toggleChemicals)}>
						<div css={category}>
							Chemicals
							{toggleChemicals ?
								<ChevronUpIcon color="#00050A" />
							:	<ChevronDownIcon color="#00050A" />}
						</div>
					</button>
					{toggleChemicals && (
						<ul>
							{(chemicals ?? []).map((chemical: PartCategory) => (
								<li key={chemical?.id} css={subCategories}>
									<NavLink
										to={`/shop/search?categories=${chemical?.id}&ordering=title`}
									>
										{chemical?.name}
									</NavLink>
								</li>
							))}
						</ul>
					)}
				</li>
				<li css={navElement}>
					<NavLink to="/shop/search?featured=true">
						<div css={category}>Trending</div>
					</NavLink>
				</li>
				<li css={navElement}>
					<NavLink to="/shop/search?clearance=true">
						<div css={category}>Clearance</div>
					</NavLink>
				</li>
			</ul>
		</nav>
	)
}

const navContainer = css`
	padding-right: 2rem;
`

const navElement = css`
	font-weight: 500;
	padding: 0.5rem;

	a {
		font-weight: 500;
		color: #00050a;
		text-decoration: none;
	}

	&:first-of-type {
		margin-top: 1.5rem;
	}

	button {
		background: none;
		border: none;
		cursor: pointer;
		padding: 0;
		width: 100%;
	}
`

const category = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #00050a;
	font-size: 1rem;
`

const subCategories = css`
	padding: 0.6rem;
	margin-left: 1rem;

	a {
		color: #00050a;
		font-size: 1rem;
		font-weight: 400;
		text-decoration: none;
	}
`
