import React, { memo } from "react"

import { Modal } from "@ncs/web-legos"

import { PartSearchFilters } from "../PartSearchFilters"

export interface SearchFilterModalProps {
	isOpen: boolean
	onClose: () => void
}

export const SearchFilterModal: React.FC<SearchFilterModalProps> = memo(({ isOpen, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<PartSearchFilters />
		</Modal>
	)
})
