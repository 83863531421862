import React from "react"
export interface ChevronRightIconProps {
	color?: string
}

export const ChevronRightIcon: React.FC<ChevronRightIconProps> = ({ color, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M17.2959 12.7969L9.7959 20.2969C9.58455 20.5082 9.29791 20.6269 8.99902 20.6269C8.70014 20.6269 8.41349 20.5082 8.20215 20.2969C7.9908 20.0855 7.87207 19.7989 7.87207 19.5C7.87207 19.2011 7.9908 18.9145 8.20215 18.7031L14.9062 12.0009L8.20402 5.29687C8.09937 5.19223 8.01636 5.06799 7.95973 4.93126C7.90309 4.79454 7.87394 4.64799 7.87394 4.5C7.87394 4.35201 7.90309 4.20546 7.95973 4.06873C8.01636 3.932 8.09937 3.80777 8.20402 3.70312C8.30867 3.59848 8.4329 3.51547 8.56963 3.45883C8.70636 3.4022 8.8529 3.37305 9.0009 3.37305C9.14889 3.37305 9.29543 3.4022 9.43216 3.45883C9.56889 3.51547 9.69312 3.59848 9.79777 3.70312L17.2978 11.2031C17.4025 11.3078 17.4856 11.4321 17.5422 11.5689C17.5988 11.7057 17.6279 11.8523 17.6277 12.0004C17.6275 12.1485 17.5981 12.295 17.5412 12.4317C17.4843 12.5684 17.4009 12.6925 17.2959 12.7969Z"
				fill={color || "white"}
			/>
		</svg>
	)
}
