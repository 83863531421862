import React, { Dispatch, SetStateAction, useState } from "react"
import { css, Theme } from "@emotion/react"
import { useAuth, CustomerSite, UserProfile, useUserProfile } from "@ncs/ncs-api"
import { formatCurrency } from "@ncs/ts-utils"
import {
	Box,
	Card,
	Divider,
	ErrorText,
	Heading,
	Price,
	Paragraph,
	TextInput,
	nextThread,
} from "@ncs/web-legos"
import { useShopContext } from "~/contexts"
import { OrderSummaryRow } from "~/shared-components"
import { PlaceOrderButton } from "./PlaceOrderButton"
export interface OrderSummaryEcommProps {
	isDb?: boolean
	onPlaceOrder: () => Promise<void>
	setErrorMessage: Dispatch<SetStateAction<string | null>>
	shipToSite: CustomerSite | null | undefined
	totalItemCount: number
	orderGrandTotal: number
	shipmentPartsSubtotal: number
	shippingTotal: number
	totalTax: number
	isCanada: boolean
	isSubmitting: boolean
	errorMessage: string | null
	profile: UserProfile | undefined
}
export const OrderSummaryEcomm: React.FC<OrderSummaryEcommProps> = ({
	isDb,
	onPlaceOrder,
	orderGrandTotal,
	isCanada,
	setErrorMessage,
	errorMessage,
	shipToSite,
	isSubmitting,
	totalItemCount,
	shipmentPartsSubtotal,
	shippingTotal,
	totalTax,
}) => {
	//const [poError, setPoError] = useState<string | null>(null) ?
	const auth = useAuth()
	const isPunchout = !!auth.punchOutSessionId
	const [{ cart, checkout }, shopDispatch] = useShopContext()
	const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string | null>()
	const [profile] = useUserProfile(auth?.user?.id)
	const isCartEmpty = cart.length === 0

	const setTaxValue = () => {
		if (isCartEmpty) {
			return 0
		}
		if (isCanada) {
			return `${formatCurrency(totalTax)} (TBD)`
		}
		return totalTax
	}

	return (
		<Card css={orderSummaryCardStyle}>
			<Heading bold mb={1}>
				Your order
			</Heading>
			<OrderSummaryRow label={`Subtotal`} amount={shipmentPartsSubtotal} />
			<Paragraph css={itemCountStyle}>{`${totalItemCount} item${
				totalItemCount !== 1 ? "s" : ""
			}`}</Paragraph>
			<OrderSummaryRow
				//isLoading={isLoading}
				label={profile && !profile.isGuest ? "Freight(¹)" : "Freight"}
				amount={
					isCartEmpty ? 0
					: isCanada ?
						`${formatCurrency(shippingTotal)} (TBD)`
					:	shippingTotal
				}
			/>
			<OrderSummaryRow
				label={profile && !profile.isGuest ? "Taxes(²)" : "Taxes"}
				amount={setTaxValue()}
			/>
			<Divider />
			<Box display="flex" justifyContent="space-between">
				<Paragraph bold css={totalLabelStyle}>
					Total:
				</Paragraph>
				<Price price={isDb ? orderGrandTotal - shippingTotal : orderGrandTotal} />
			</Box>
			<Divider />
			<div css={purchaseOrderStyle}>
				<div>
					<Paragraph bold css={purchaseLabelStyle}>
						Purchase Order
					</Paragraph>
					{profile?.poRequired ?
						<p css={purchaseOrderOptionalStyle}>(Required)</p>
					:	<p css={purchaseOrderOptionalStyle}>(Optional)</p>}
				</div>
				<TextInput
					placeholder="Enter PO#"
					ml={1}
					mb={0}
					value={purchaseOrderNumber}
					css={
						checkout &&
						cart &&
						profile?.poRequired &&
						!checkout.purchaseOrder &&
						cart.length > 0 &&
						(checkout.shipToSiteId || checkout.alternateAddress) &&
						(checkout.selectedPaymentMethod || isPunchout) &&
						css`
							border: 1.5px solid red;
							::placeholder {
								color: red;
							}
						`
					}
					onChange={(value: string | null) => {
						setPurchaseOrderNumber(value)
						nextThread(() =>
							shopDispatch({
								type: "set purchase order",
								payload: value,
							})
						)
					}}
				/>
			</div>
			<Divider />
			<div css={disclaimerContainerStyle}>
				{profile && !profile.isGuest && (
					<>
						<Paragraph color="#4B5563" css={disclaimerStyle}>
							¹ Freight is estimated and will be reconciled at time of shipping.
						</Paragraph>
						<Paragraph color="#4B5563" css={disclaimerStyle}>
							² Taxes will be assessed at time of invoice.
						</Paragraph>
					</>
				)}
				<Paragraph color="#4B5563" css={disclaimerStyle}>
					By clicking Place Order, you accept our&nbsp;
					<a
						css={linkStyle}
						href="https://ncswash.com/privacy-policy/"
						target="_blank"
						rel="noreferrer"
					>
						Privacy Policy
					</a>
					{" or "}
					<a
						css={linkStyle}
						href="https://ncswash.com/wp-content/uploads/2023/06/NCS-PO-STANDARD-TERMS-AND-CONDITIONS-1.pdf"
						target="_blank"
						rel="noreferrer"
					>
						Terms & Conditions
					</a>
					.
				</Paragraph>
			</div>
			<PlaceOrderButton
				onPlaceOrder={onPlaceOrder}
				isLoading={isSubmitting}
				setErrorMessage={setErrorMessage}
				shipToSite={shipToSite}
			/>
			{!!errorMessage && <ErrorText mb={1}>{errorMessage}</ErrorText>}
		</Card>
	)
}

const linkStyle = css`
	font-size: 0.68rem;
	color: #0b75e1;
	text-decoration: underline;
`

const disclaimerStyle = css`
	color: #4b5563;
	font-family: "Atlas Grotesk";
	font-size: 0.68rem;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	margin-bottom: 0.2rem;
`
const totalLabelStyle = css`
	color: #003264;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
`
const purchaseLabelStyle = css`
	color: #111827;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
`
const disclaimerContainerStyle = css`
	margin-bottom: 1.5rem;
`
const itemCountStyle = css`
	color: #6b7280;
	font-size: 0.875rem;
	margin-top: -1rem;
	margin-bottom: 0.8rem;
`

const purchaseOrderOptionalStyle = css`
	color: #6b7280;
	font-size: 0.875rem;
`

const purchaseOrderStyle = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	input {
		padding: 0.7rem;
	}
	div {
		width: 10rem;
	}
`
const orderSummaryCardStyle = (theme: Theme) => css`
	position: sticky;
	top: 1rem;
	width: 25rem;
	${theme.breakpoints.down("sm")} {
		position: relative;
		top: 0;
		width: auto;
	}
`
