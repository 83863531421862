import { FC } from "react"
import { css } from "@emotion/react"

interface ShopBarNavSubmenuEcommProps {
	items: {
		label: string | number
		onClickValue: string | number
	}[]
	onClick: (itemValue: string) => void
	columnCount?: number
}

export const ShopBarNavSubmenuEcomm: FC<ShopBarNavSubmenuEcommProps> = ({
	items,
	onClick,
	columnCount,
}) => {
	return (
		<div css={menuContainerStyle}>
			<ul
				css={[
					navItemListStyle,
					css`
						column-count: ${columnCount ?? 3};
					`,
				]}
			>
				{items.map((item) => (
					<li key={item.onClickValue}>
						<button onClick={() => onClick(item.onClickValue.toString())}>
							{item.label}
						</button>
					</li>
				))}
			</ul>
		</div>
	)
}

const menuContainerStyle = css`
	max-height: 40rem;
	overflow-y: auto;
`
const navItemListStyle = () => css`
	break-inside: avoid-column;
	padding: 1.5rem;

	li {
		width: 17rem;

		button {
			width: 100%;
			background: none;
			border: 0;
			color: #003264;
			text-align: left;
			padding: 0.75rem;

			&:hover {
				background: #0b75e1;
				color: white !important;
			}
		}
	}
`
