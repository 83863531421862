import React from "react"
import { initialCustomerPartQueryParams } from "@ncs/ncs-api"
import { Box, Paragraph, SelectEcomm, useUrlState } from "@ncs/web-legos"
import { PartSearchResultsUrlState } from "~/views/shop/part-search-results/PartSearchResults"

const sortOptions = [
	{ value: "title", text: "Name A - Z" },
	{ value: "-title", text: "Name  Z - A" },
	{ value: "price", text: "Price low to high" },
	{ value: "-price", text: "Price high to low" },
	{ value: "online_part_number", text: "Product number" },
]

type PartSortUrlState = Pick<PartSearchResultsUrlState, "ordering">

export const PartSortEcomm: React.FC = React.memo(() => {
	const [{ ordering }, { updateUrlValue }] = useUrlState<PartSortUrlState>({
		ordering: initialCustomerPartQueryParams.ordering,
	})

	return (
		<Box display="flex" flexShrink={0} alignItems="center" ml={1}>
			<Paragraph mr="0.75rem" flexShrink={0} bold small>
				Sort by:{" "}
			</Paragraph>

			<SelectEcomm
				options={sortOptions}
				value={ordering ?? "title"}
				showNoSelectionOption={false}
				onChange={(newValue) => updateUrlValue("ordering", newValue)}
				mb={0}
			/>
		</Box>
	)
})
