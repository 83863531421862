import React, { useState } from "react"
import { OnlinePartDetails } from "@ncs/ncs-api"
import { css, Theme } from "@emotion/react"

export interface PartDetailImagesEcommProps {
	partDetails?: OnlinePartDetails
	setVariant: (option: string, variant: string) => void
}

export const PartDetailImagesEcomm: React.FC<PartDetailImagesEcommProps> = ({
	partDetails,
	setVariant,
}) => {
	const [selectedItem, setselectedItem] = useState<number | null>(null)

	if (!partDetails || !partDetails.children || partDetails.children.length <= 1) {
		return null
	}

	const handleChange = (index: number) => {
		setselectedItem(index)
	}

	return (
		<div css={listContainer}>
			<div css={childrenContainer}>
				{partDetails?.children
					?.filter((item) => item.options && item.options.length > 0)
					.map((item, index) => (
						<>
							<label key={index} css={labelStyle}>
								<input
									type="radio"
									name="product-selection"
									checked={selectedItem === index}
									onChange={() => {
										handleChange(index)
										setVariant(item.options[0].option, item.options[0].variant)
									}}
									css={radioButton}
								/>
								<img
									src={item.imageUrl}
									alt="Product"
									css={[image, selectedItem === index && selectedImage]}
								/>
							</label>
						</>
					))}
			</div>
		</div>
	)
}

const listContainer = (theme: Theme) => css`
	overflow-x: auto;
	max-width: 24rem;
	${theme.breakpoints.down("sm")} {
		max-width: none;
	}
	padding-bottom: 1em;
	&::-webkit-scrollbar {
		height: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 2px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}
`

const childrenContainer = (theme: Theme) => css`
	padding-left: 5em;
	display: flex;
	flex-direction: row;
	margin-top: 2rem;
	justify-content: center;

	&::-webkit-scrollbar {
		display: none;
	}
`

const labelStyle = css`
	display: inline-block;
	cursor: pointer;
	margin-right: 18px;
`

const radioButton = css`
	display: none;
`

const selectedImage = css`
	border: 2px solid #0b75e1;
	box-sizing: border-box;
`

const image = css`
	border-radius: 0.37rem;
	border: 1px solid #e5e6e6;
	margin-right: 1.18rem;
	padding: 0.5rem;
	width: 5.6rem;
	height: 5.6rem;
	max-width: 5.6rem;
	max-height: 5.6rem;
	min-width: 5.6rem;
	min-height: 5.6rem;

	&:last-of-type {
		margin-right: 0;
	}
`
