import { FC, useEffect } from "react"

import { useAuth } from "@ncs/ncs-api"
import { LoadingSpinner, useUrlState } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"
import { PageContentWrapper } from "~/shared-components"

export type PunchOrderFinishedUrlState = {
	redirect: string | null
}

/**
 * This where you land after posting a Punchout order. We'll reset the application
 * state that we need to and then redirect you on your way.
 */
export const PunchoutOrderFinished: FC = () => {
	const { user, logout } = useAuth()
	const [{ redirect }] = useUrlState<PunchOrderFinishedUrlState>({ redirect: null })
	const [{ cart, checkout }, shopDispatch] = useShopContext()

	useEffect(() => {
		// First clear out all the checkout stuff because that's persisted in local storage.
		shopDispatch({
			type: "reset checkout state",
		})
	}, [shopDispatch])

	useEffect(() => {
		// When the cart is empty, go ahead and clear all the logout stuff since that was
		// based on a temporary punchout ID.
		if (cart.length === 0 && checkout.shipments === null) {
			shopDispatch({
				type: "reset checkout state",
			})
			logout()
		}
	}, [cart, checkout.shipments, logout, shopDispatch])

	useEffect(() => {
		// When we're logged out, then we can assume we're done with all the stuff
		// we needed to clear out here before leaving.
		if (!user) {
			window.location.replace(redirect || "/")
		}
	}, [user, redirect])

	return (
		<PageContentWrapper>
			<LoadingSpinner text="Redirecting..." />
		</PageContentWrapper>
	)
}
