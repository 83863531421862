import React, { useEffect, useState } from "react"
import { css, SerializedStyles, Theme } from "@emotion/react"
import {
	AnimatedEntrance,
	Box,
	BoxProps,
	Button,
	IconButton,
	nextThread,
	NumericInputEcomm,
	Paragraph,
	SelectEcomm,
	usePrevious,
} from "@ncs/web-legos"
import { UnitMapping } from "./UnitMapping"

const options = Array.from({ length: 30 }, (_, i) => ({
	value: String(i + 1),
	text: String(i + 1),
})).concat([{ value: "30+", text: "30+" }])

interface QuantityControlProps extends Omit<BoxProps, "display" | "onChange"> {
	unitOfMeasure?: string | null | undefined
	value?: number
	onChange: (newValue: number) => void
	onChooseZero?: () => void
	useUpdateButton?: boolean
	iconCss?: SerializedStyles
	labelPosition?: "top" | "left"
}

export const QuantityControlEcomm: React.FC<QuantityControlProps> = ({
	value: propValue,
	onChange,
	onChooseZero,
	iconCss,
	useUpdateButton = false,
	unitOfMeasure = null,
	...rest
}) => {
	const [mode, setMode] = useState<"select" | "input">(
		propValue && propValue > 30 ? "input" : "select"
	)
	const [localValue, setLocalValue] = useState(propValue)
	const [showUpdateButton, setShowUpdateButton] = useState(false)

	const prevPropValue = usePrevious(propValue)

	useEffect(() => {
		if (propValue !== prevPropValue && propValue !== localValue) {
			setLocalValue(propValue)
			if (propValue != null && propValue < 30) {
				setMode("select")
			}
		}
	}, [localValue, propValue, prevPropValue])

	const handleSelectChange = (newValue?: string | null | null) => {
		if (newValue === "0" && onChooseZero) {
			onChooseZero()
		} else if (newValue === "30+") {
			setMode("input")
			setShowUpdateButton(true)
			setLocalValue(30)
			nextThread(() => onChange(30))
		} else {
			setLocalValue(Number(newValue ?? 0))
			nextThread(() => onChange(Number(newValue ?? 0)))
		}
	}

	const handleInputChange = (newValue?: number) => {
		if (useUpdateButton) {
			setLocalValue(newValue)
		} else {
			setLocalValue(newValue ?? 0)
			nextThread(() => onChange(newValue ?? 0))
		}
	}

	const onSaveInput = () => {
		if (!localValue && onChooseZero) {
			onChooseZero()
			return
		}
		onChange(Number(localValue ?? 0))
		setShowUpdateButton(false)
		if (localValue && localValue < 30) {
			setMode("select")
		}
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (event.key === "Enter") {
			const target = event.target as HTMLInputElement | HTMLSelectElement
			const selectedValue = target.value?.trim()

			if (!selectedValue) return

			if (mode === "select") {
				handleSelectChange(selectedValue)
			} else {
				const numericValue = Number(selectedValue)
				if (!isNaN(numericValue)) {
					handleInputChange(numericValue)
				}
			}
			onSaveInput()
		}
	}

	return (
		<div {...rest}>
			<Paragraph css={quantityLabel}>Quantity</Paragraph>
			<Box css={box}>
				{mode === "select" ?
					<>
						<div>
							<SelectEcomm
								options={options}
								value={String(localValue)}
								onChange={handleSelectChange}
								showNoSelectionOption={false}
								css={inputStyle}
								iconCss={iconCss}
								onKeyDown={handleKeyDown}
								mb={0}
							/>
						</div>
						{unitOfMeasure && (
							<Paragraph css={unitStyle}>{UnitMapping(unitOfMeasure)}</Paragraph>
						)}
					</>
				:	<Box css={box}>
						<div css={editBox}>
							<NumericInputEcomm
								css={inputStyle}
								value={localValue}
								onChange={handleInputChange}
								onFocus={() => setShowUpdateButton(true)}
								min={0}
								mb={0}
								onKeyDown={handleKeyDown}
								decimalScale={0}
							/>
							<AnimatedEntrance
								show={useUpdateButton && showUpdateButton}
								css={saveIcon}
							>
								<Button icon="check" onClick={onSaveInput} />
							</AnimatedEntrance>
						</div>
						{unitOfMeasure && (
							<Paragraph css={unitStyle}>{UnitMapping(unitOfMeasure)}</Paragraph>
						)}
					</Box>
				}

				<div>
					{!!onChooseZero && (
						<Box css={trashCan(unitOfMeasure)}>
							<IconButton icon="trash" onClick={onChooseZero} />
						</Box>
					)}
				</div>
			</Box>
		</div>
	)
}

const unitStyle = (theme: Theme) => css`
	font-weight: 500;
	margin-left: 1rem;
	margin-right: 0.5rem;
	width: 2.8rem;

	${theme.breakpoints.down("xs")} {
		display: none;
	}
`

const inputStyle = css`
	width: 5.7rem;
	text-align: center;

	input,
	select {
		padding: 0.5rem 1rem;
		text-align: center;
	}
`

const trashCan = (unitOfMeasure: string | null) => css`
	margin-top: -0.6rem;
	margin-left: ${unitOfMeasure ? 0 : "1rem"};
`

const quantityLabel = css`
	color: #111827;
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 0.5rem;
`

const box = css`
	display: flex;
	align-items: center;
`

const saveIcon = css`
	margin-left: 1rem;
	&:hover {
		cursor: pointer;
	}
`

const editBox = css`
	position: relative;
	display: flex;
	align-items: center;
`
