import { FC, useState } from "react"
import { css } from "@emotion/react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { initialCustomerPartQueryParams, usePartCategories, usePartChemicals } from "@ncs/ncs-api"
import { AnimatedEntrance, encodeUrlState, ChevronDownIcon, useUrlState } from "@ncs/web-legos"
import type { PartSearchResultsUrlState } from "~/views/shop/part-search-results"
import { ShopBarNavSubmenuEcomm } from "./ShopBarNavSubmenuEcomm"

export type ShopBarNavUrlState = Pick<
	PartSearchResultsUrlState,
	"categories" | "manufacturers" | "systems" | "featured" | "clearance"
>

export const ShopBarNavEcomm: FC = () => {
	const history = useHistory()
	const [categories] = usePartCategories()
	const [chemicals] = usePartChemicals()
	// eslint-disable-next-line
	const [showCategoriesSubmenu, setShowCategoriesSubmenu] = useState(false)
	const [showChemicalsSubmenu, setShowChemicalsSubmenu] = useState(false)

	const [, { setUrlState }] = useUrlState<ShopBarNavUrlState>({
		categories: initialCustomerPartQueryParams.categories,
		manufacturers: initialCustomerPartQueryParams.manufacturers,
		systems: initialCustomerPartQueryParams.systems,
		featured: null,
		clearance: null,
	})
	const isOnSearchPage = useRouteMatch("/shop/search")

	const viewResult = (urlState: Partial<PartSearchResultsUrlState>) => {
		if (isOnSearchPage) {
			setUrlState({
				...initialCustomerPartQueryParams,
				...urlState,
			})
		} else {
			history.push({
				pathname: "/shop/search",
				search: encodeUrlState<PartSearchResultsUrlState>(urlState),
			})
		}
	}

	const onViewCategory = (categoryId: string) => {
		setShowCategoriesSubmenu(false)
		const urlState: Partial<PartSearchResultsUrlState> = {
			categories: [categoryId],
		}
		viewResult(urlState)
	}

	const onViewChemical = (chemicalId: string) => {
		setShowCategoriesSubmenu(false)
		const urlState: Partial<PartSearchResultsUrlState> = {
			chemicals: [chemicalId],
		}
		viewResult(urlState)
	}

	return (
		<ul css={navListStyle}>
			<li
				className="has-children"
				onMouseEnter={() => setShowCategoriesSubmenu(true)}
				onMouseLeave={() => setShowCategoriesSubmenu(false)}
			>
				<span>
					<span>Parts</span>
					<span style={{ marginLeft: "5px" }}>
						<ChevronDownIcon />
					</span>
				</span>
				<AnimatedEntrance
					show={showCategoriesSubmenu}
					css={submenuContainerStyle}
					duration={200}
					delay={200}
				>
					<ShopBarNavSubmenuEcomm
						items={(categories ?? []).map((system) => ({
							label: system.name ?? system.id,
							onClickValue: system.id,
						}))}
						onClick={onViewCategory}
					/>
				</AnimatedEntrance>
			</li>
			<li
				className="has-children"
				onMouseEnter={() => setShowChemicalsSubmenu(true)}
				onMouseLeave={() => setShowChemicalsSubmenu(false)}
			>
				<span>
					<span>Chemicals</span>
					<span style={{ marginLeft: "5px" }}>
						<ChevronDownIcon />
					</span>
				</span>
				<AnimatedEntrance
					show={showChemicalsSubmenu}
					css={submenuContainerStyle}
					duration={200}
					delay={200}
				>
					<ShopBarNavSubmenuEcomm
						columnCount={2}
						items={(chemicals ?? []).map((system) => ({
							label: system.name ?? system.id,
							onClickValue: system.id,
						}))}
						onClick={onViewChemical}
					/>
				</AnimatedEntrance>
			</li>
			<li>
				<button css={navLinkButton} onClick={() => viewResult({ featured: true })}>
					<span>Trending</span>
				</button>
			</li>
			<li>
				<button css={navLinkButton} onClick={() => viewResult({ clearance: true })}>
					<span>Clearance</span>
				</button>
			</li>
		</ul>
	)
}

const navListStyle = () => css`
	display: flex;
	align-items: center !important;
	justify-content: space-between !important;
	background-color: #f2f2f3;
	color: #00050a;
	font-size: 14px;
	font-style: normal;
	font-weight: 500 !important;
	line-height: normal;
	display: flex;
	column-gap: 0.5rem;
	padding: 1rem 0;
	z-index: 2;

	& * {
		color: #00050a !important;
	}

	li {
		font-size: 14px !important;
		line-height: normal;
		font-weight: 500 !important;
		font-style: normal;
		box-sizing: border-box;
		position: relative;
		margin-right: 1.5rem;
		padding-bottom: 2px;

		/* This is required to keep the hover state active */
		&.has-children::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 4rem;
			z-index: 2;
		}

		&:hover,
		button:hover {
			cursor: pointer;
		}

		> span,
		a {
			color: white;
			background: none;
			padding: 0;
			border: 0;
			text-decoration: none;
			&:hover,
			&:focus {
				color: white;
			}
		}
		&.has-children {
			transition: border-color 300ms ease-out 100ms;
			> span {
				cursor: pointer;
			}
			&:hover {
				border-color: white;
			}
		}
	}
`

const submenuContainerStyle = css`
	position: absolute;
	z-index: 1;
	top: 2rem;
	left: -1rem;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
	background: #ffffff;
	border-radius: 0.5rem;

	&:hover {
		box-shadow: 0 0 8px 0 #d1d5db;
	}
`

const navLinkButton = css`
	color: white;
	border: 0;
	padding: 0;
	background: none;
`
