import { FC } from "react"
import { css } from "@emotion/react"
import { CustomerBrandCard } from "@ncs/ncs-api"
import { Card, Link } from "@ncs/web-legos"

interface ShopBrandCtaProps {
	card: CustomerBrandCard
}

export const ShopBrandCtaEcomm: FC<ShopBrandCtaProps> = ({ card }) => {
	const isExternalLink = card.buttonDestinationUrl.startsWith("http")

	return (
		<Card variant="plain" maxHeight={15.625} textAlign="center">
			<Link
				to={card.buttonDestinationUrl}
				newTab={card.buttonDestinationOpensNewWindow}
				external={isExternalLink}
			>
				<img src={card.logoImageUrl} css={logoImage} alt={`Logo for ${card.name}`} />
			</Link>
			<Link
				to={card.buttonDestinationUrl}
				newTab={card.buttonDestinationOpensNewWindow}
				external={isExternalLink}
			/>
			<span css={taglineTextCss}>{card.taglineText}</span>
		</Card>
	)
}

const taglineTextCss = css`
	display: block;
	font-size: 0.875rem;
	color: #1f2937;
`

const logoImage = css`
	max-width: 11.5rem;
	margin-bottom: 1rem;
	width: 100%;
`
