import { css } from "@emotion/react"

import AtlasGroteskBlack from "~/fonts/AtlasGroteskBlack.otf"
import AtlasGroteskBlackItalic from "~/fonts/AtlasGroteskBlackItalic.otf"

import AtlasGroteskBold from "~/fonts/AtlasGroteskBold.otf"
import AtlasGroteskBoldItalic from "~/fonts/AtlasGroteskBoldItalic.otf"

import AtlasGroteskLight from "~/fonts/AtlasGroteskLight.otf"
import AtlasGroteskLightItalic from "~/fonts/AtlasGroteskLightItalic.otf"

import AtlasGroteskMedium from "~/fonts/AtlasGroteskMedium.otf"
import AtlasGroteskMediumItalic from "~/fonts/AtlasGroteskMediumItalic.otf"

import AtlasGroteskRegular from "~/fonts/AtlasGroteskRegular.otf"
import AtlasGroteskRegularItalic from "~/fonts/AtlasGroteskRegularItalic.otf"

import AtlasGroteskThin from "~/fonts/AtlasGroteskThin.otf"
import AtlasGroteskThinItalic from "~/fonts/AtlasGroteskThinItalic.otf"

export const fonts = css`
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 900;
		font-style: normal;
		src: url(${AtlasGroteskBlack}) format("opentype");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 900;
		font-style: italic;
		src: url(${AtlasGroteskBlackItalic}) format("opentype");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 700;
		font-style: normal;
		src: url(${AtlasGroteskBold}) format("opentype");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 700;
		font-style: italic;
		src: url(${AtlasGroteskBoldItalic}) format("opentype");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 500;
		font-style: normal;
		src: url(${AtlasGroteskMedium}) format("opentype");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 500;
		font-style: italic;
		src: url(${AtlasGroteskMediumItalic}) format("opentype");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 400;
		font-style: normal;
		src: url(${AtlasGroteskRegular}) format("opentype");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 400;
		font-style: italic;
		src: url(${AtlasGroteskRegularItalic}) format("opentype");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 300;
		font-style: normal;
		src: url(${AtlasGroteskLight}) format("opentype");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 300;
		font-style: italic;
		src: url(${AtlasGroteskLightItalic}) format("opentype");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 200;
		font-style: normal;
		src: url(${AtlasGroteskThin}) format("opentype");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: 200;
		font-style: italic;
		src: url(${AtlasGroteskThinItalic}) format("opentype");
	}
`
