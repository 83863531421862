import React from "react"
import { formatCurrency } from "@ncs/ts-utils"
import { Box } from "../layout"
import { Paragraph } from "./Paragraph"
import { css } from "@emotion/react"

export interface PartPriceEcommProps {
	price: number | string | null
	netPrice: number | string | null
	isParent?: boolean | null
	quantity?: number | null
}

export const PartPriceEcomm: React.FC<PartPriceEcommProps> = ({
	price,
	netPrice,
	isParent = false,
	quantity,
}) => {
	const parsedPrice = parseFloat(price?.toString() || "0")
	const parsedNetPrice = parseFloat(netPrice?.toString() || "0")

	const calculateDiscount = (netPrice: number, price: number): number => {
		if (price > netPrice) {
			return Math.round(((price - netPrice) / price) * 100)
		}
		return 0
	}

	const discount = calculateDiscount(parsedNetPrice, parsedPrice)

	const renderPriceContent = () => {
		if (quantity) {
			return parsedNetPrice < parsedPrice ?
					<div css={priceBoxQuantity}>
						<div>
							<Paragraph fontSize={1.25} customColor="#10A85F" bold>
								{formatCurrency(parsedNetPrice * quantity)}
							</Paragraph>
							<Paragraph css={strikedPrice}>
								{formatCurrency(parsedPrice * quantity)}
							</Paragraph>
						</div>
						{quantity > 1 && (
							<Paragraph small css={eachPrice}>
								{formatCurrency(parsedNetPrice)} each
							</Paragraph>
						)}
					</div>
				:	<div css={priceBoxQuantity}>
						<Paragraph fontSize={1.25} customColor="#111827" bold>
							{formatCurrency(parsedPrice * quantity)}
						</Paragraph>
						{quantity > 1 && (
							<Paragraph small css={eachPrice}>
								{formatCurrency(parsedNetPrice)} each
							</Paragraph>
						)}
					</div>
		} else {
			return parsedNetPrice < parsedPrice ?
					<div css={priceBox}>
						<Paragraph
							fontSize={1.25}
							customColor={isParent ? "black" : "#10A85F"}
							bold
						>
							{formatCurrency(parsedNetPrice)}
							{isParent ? " +" : null}
						</Paragraph>
						<Paragraph small customColor={discount > 0 ? "#374151" : "transparent"}>
							Your Price (${discount}% Off)
						</Paragraph>
					</div>
				:	<div css={priceBox}>
						<Paragraph fontSize={1.25} customColor="#111827" bold>
							{formatCurrency(isParent ? parsedNetPrice : parsedPrice)}
							{isParent ? " +" : null}
						</Paragraph>
						{(quantity ?? 1) > 1 && (
							<Paragraph small css={eachPrice}>
								{formatCurrency(parsedNetPrice)} each
							</Paragraph>
						)}
					</div>
		}
	}

	return <Box>{renderPriceContent()}</Box>
}

const priceBox = css`
	height: 3rem;
	display: flex;
	flex-direction: column;
`

const eachPrice = css`
	color: #4b5563;
	font-weight: 400;
`

const priceBoxQuantity = css`
	height: 3rem;
	display: flex;
	flex-direction: column;

	> div {
		display: flex;
	}
`

const strikedPrice = css`
	color: #111827;
	font-size: 1.25rem;
	font-weight: 400;
	text-decoration: line-through;
	margin-left: 1.25rem;
`
