import React, { useEffect, useState } from "react"
import { css, Theme } from "@emotion/react"
import { NavLink, useLocation } from "react-router-dom"
import { LogoutIcon, Modal, usePrevious } from "@ncs/web-legos"
import { APPLICATION, useAuth, useIsUser, UserId, useUserCanUse } from "@ncs/ncs-api"
import { SupportIssueModal } from "~/shared-components"

export interface MobileAccountMenuModalProps {
	isOpen: boolean
	onClose: () => void
}

export const MobileAccountMenuModal: React.FC<MobileAccountMenuModalProps> = React.memo(
	({ isOpen, onClose }) => {
		const canUseConnectivity = useUserCanUse(APPLICATION.Connectivity)
		const canUseEquipment = useUserCanUse(APPLICATION.Equipment)
		const [showTicketModal, setShowTicketModal] = useState(false)
		const canUseDispatch = useUserCanUse([
			APPLICATION.CreateDispatch,
			APPLICATION.SearchDispatch,
		])
		const isDb = useIsUser(UserId.DrivenBrands)
		const location = useLocation()
		const auth = useAuth()

		const prevLocation = usePrevious(location)
		useEffect(() => {
			// Close the nav modal if you click a link.
			if (location.key !== prevLocation.key) {
				onClose()
			}
		}, [onClose, location, prevLocation])

		return (
			<>
				<Modal isOpen={isOpen} onClose={onClose} fullScreen showCloseButton={false}>
					<p css={helloStyle}>Hello</p>
					<p css={usernameStyle}>{auth?.user?.name}!</p>

					<nav css={navStyle}>
						<ul>
							{!isDb && (
								<li>
									<NavLink to="/account">My Account</NavLink>
								</li>
							)}

							{!isDb && canUseDispatch && (
								<li>
									<NavLink to="/service-requests">My Service Requests</NavLink>
								</li>
							)}

							{canUseConnectivity && (
								<li>
									<NavLink to="/connectivity">Connectivity</NavLink>
								</li>
							)}

							{!isDb && canUseEquipment && (
								<li>
									<NavLink to="/my-equipment">My Equipment</NavLink>
								</li>
							)}
							<li>
								<button onClick={() => setShowTicketModal(true)} css={buttonStyle}>
									Contact Us
								</button>
							</li>
						</ul>
					</nav>
					<div css={modalFooterStyle}>
						<NavLink to="/auth/logout">
							<>
								<LogoutIcon />
								<span>Logout</span>
							</>
						</NavLink>
					</div>
				</Modal>
				<SupportIssueModal
					isOpen={showTicketModal}
					onClose={() => setShowTicketModal(false)}
				/>
			</>
		)
	}
)

const buttonStyle = css`
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	font: inherit;
	color: inherit;
	cursor: pointer;
	outline: none;
	text-align: inherit;
	display: inline-block;
`

const modalFooterStyle = (theme: Theme) => css`
	position: absolute;
	bottom: 3em;

	border-top: 1px solid ${theme.palette.colors.divider};
	width: 90%;
	padding-top: 1em;

	a {
		text-decoration: none;
		color: ${theme.palette.colors.black500};
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	svg {
		width: 16px;
		height: 14px;
		margin-right: 12px;
		margin-bottom: -2px;
	}
`

const navStyle = (theme: Theme) => css`
	padding-top: 22px;

	a,
	button {
		color: ${theme.palette.colors.black500};
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-decoration: none;
		height: 40px;
		width: 100%;
		display: inline-block;
		padding-top: 7.5px;
		padding-bottom: 10.5px;
		padding-left: 12px;
		padding-right: 22px;
	}

	li {
		height: 40px;
		vertical-align: middle;
	}

	li:hover {
		background-color: ${theme.palette.colors.blue500};
		color: white;

		a,
		button {
			color: white;
		}
	}
`

const usernameStyle = (theme: Theme) => css`
	color: ${theme.palette.colors.gray900};
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding-top: 8px;
	padding-bottom: 8px;
	border-bottom: 1.5px solid ${theme.palette.colors.divider};
`

const helloStyle = (theme: Theme) => css`
	color: ${theme.palette.colors.gray900};
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`
