import React from "react"
import { formatNumber } from "@ncs/ts-utils"
import { BoxProps } from "../layout"
import { Paragraph } from "../typography"
import { Skeleton } from "@material-ui/lab"

export interface ResultCountSummaryEcommProps extends BoxProps {
	currentTotal: number
	totalResults: number | null
	resultsAreApproximate?: boolean
	resultWord?: string
	searchItem?: string | null
}

export const ResultCountSummaryEcomm: React.FC<ResultCountSummaryEcommProps> = ({
	currentTotal,
	totalResults,
	resultsAreApproximate,
	resultWord = "result",
	searchItem,
}) => (
	<>
		{totalResults === null ?
			<Skeleton />
		:	<>
				<Paragraph bold fontSize={1.5} customColor="#374151">
					Search Results{searchItem ? `: ${searchItem}` : ""}
				</Paragraph>
				Showing {formatNumber(currentTotal)} {resultsAreApproximate && "approximately"} of{" "}
				{formatNumber(totalResults)} {resultWord}
				{currentTotal !== 1 ? "s" : ""}
			</>
		}
	</>
)
