import React from "react"
import { Button, Icon, Paragraph } from "@ncs/web-legos"
import { PartSearchFiltersEcomm } from "./PartSearchFiltersEcomm"
import { css } from "@emotion/react"

export interface SearchFilterModalPropsEcomm {
	isOpen: boolean
	onClose: () => void
}

export const SearchFilterModalEcomm: React.FC<SearchFilterModalPropsEcomm> = ({
	isOpen,
	onClose,
}) => {
	return (
		<a
			css={overlay(isOpen)}
			href={"null"}
			onClick={(e) => {
				e.preventDefault()
				if (e.target === e.currentTarget) {
					onClose?.()
				}
			}}
		>
			<div css={filterModal}>
				<div css={header}>
					<Paragraph bold fontSize={1.25}>
						Filters
					</Paragraph>
					<Button onClick={onClose}>
						<Icon icon="close" fontSize={1} />
					</Button>
				</div>
				<PartSearchFiltersEcomm />
			</div>
		</a>
	)
}

const overlay = (isOpen: boolean | undefined) => css`
	right: 0;
	top: 0;
	position: absolute;
	left: 0;
	bottom: 0;
	display: ${isOpen ? "block" : "none"};
	z-index: 1;
	cursor: inherit;
	background: rgba(0, 0, 0, 0.3);
	&:focus {
		outline: none;
	}
`

const filterModal = css`
	width: 100%;
	max-width: 22rem;
	border-radius: 8px;
	border: 1px solid #e5e6e6;
	background: #ffffff;
	box-shadow: 0px 0px 4px 0px #d1d5db;
	padding: 1.5rem;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	opacity: 1;
	max-height: calc(100vh - 4rem);
	overflow-y: scroll;
`

const header = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e5e6e6;
	padding-bottom: 1.5rem;

	button.MuiButtonBase-root path {
		width: 2rem;
		height: 2rem;
	}
`
