import React, { useEffect } from "react"
import { css } from "@emotion/react"
import { Link, useLocation } from "react-router-dom"
import { Box, Button, Icon, Modal, usePrevious } from "@ncs/web-legos"
import { Logo } from "./Logo"
import { SiteNavigationEcomm } from "./SiteNavigationEcomm/SiteNavigationEcomm"

export interface MobileNavModalProps {
	isOpen: boolean
	onClose: () => void
}

export const MobileNavModalEcomm: React.FC<MobileNavModalProps> = React.memo(
	({ isOpen, onClose }) => {
		const location = useLocation()

		const prevLocation = usePrevious(location)
		useEffect(() => {
			// Close the nav modal if you click a link.
			if (location.key !== prevLocation.key) {
				onClose()
			}
		}, [onClose, location, prevLocation])

		return (
			<Modal isOpen={isOpen} onClose={onClose} fullScreen showCloseButton={false}>
				<Box
					css={css`
						position: absolute;
						top: 20px;
						width: 100%;
					`}
				>
					<Button
						onClick={onClose}
						css={css`
							margin-right: 12px;
						`}
					>
						<Icon icon="bars" fontSize={1.7} />
					</Button>

					<Link to="/">
						<Logo height={1.5} />
					</Link>

					<SiteNavigationEcomm />
				</Box>
			</Modal>
		)
	}
)
