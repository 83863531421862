import React from "react"
import { css } from "@emotion/react"
import { Pagination } from "@material-ui/lab"

interface PaginationProps {
	numberOfPages: number
	fetchPage: (page: number) => void
	currentPage: number
	isLoading?: boolean
}

export const PaginationEcomm: React.FC<PaginationProps> = ({
	numberOfPages,
	currentPage,
	fetchPage,
}) => {
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		fetchPage(value)
	}

	return (
		<div css={container}>
			<Pagination
				page={currentPage}
				count={numberOfPages}
				onChange={handleChange}
				variant="outlined"
				shape="rounded"
				css={pagination}
			/>
		</div>
	)
}

const container = css`
	margin-top: 5rem;
	display: flex;
	justify-content: center;
`

const pagination = css`
	ul li button.MuiPaginationItem-rounded {
		width: 3rem;
		height: 3rem;
		margin-right: 1.5rem;
		margin-left: 0;
		border: 1px solid #9ca3af;
		border-radius: 0.5rem;
		color: #9ca3af;
		font-weight: 500;
	}

	ul li div.MuiPaginationItem-root {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3rem;
		height: 3rem;
		margin-right: 1.5rem;
		margin-left: 0;
	}

	ul li button.MuiPaginationItem-page.Mui-selected {
		background: #003264;
		color: #fff;
		border: 1px solid #003264;
	}

	ul li:first-of-type button,
	ul li:last-of-type button {
		border: 0;
	}

	button svg.MuiSvgIcon-root {
		width: 2rem;
		height: 2rem;
		fill: #9ca3af;
	}
`
